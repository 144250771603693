#tx-solr-search-tabs {
    ul {
        margin: 0;
        padding-left: 0;
        display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox;  /* TWEENER - IE 10 */
        display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
        flex-flow: row wrap;
        position: relative;
        max-width: none;
    }
    li {
        width: 50%;
        display: inline-block;
        text-align: center;
    }
    a {
        text-decoration: none;
        display: block;
        padding: 20px 0;
        @include font-size(17px);
    }
    .active a {
        background: $color-border;
        font-weight: 700;
        cursor: default;
    }
}

