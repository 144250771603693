#logo {
    margin: 0;

    img {
        width: auto;
        height: 70px;
    }

    @media #{$screen-header-rule-min} {
        img {
            height: 80px;
        }
    }
}
