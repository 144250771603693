$margin: 4%;
$width-1of4: calculateBlockWidth($margin, 4);
$width-1of2: calculateBlockWidth($margin, 2);

.direct--access {
    ul {
        padding: 20px 0 0 0;
        margin: 0;

        @media #{$screen-m-rule-min} {
            padding: 50px 0;
            display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
            display: -ms-flexbox;  /* TWEENER - IE 10 */
            display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
            display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
        }
    }

    li {
        list-style: none;
        width: 100%;
        margin: 0 0 10px 0;
        -webkit-box-flex: 1;   /* OLD - iOS 6-, Safari 3.1-6 */
        -webkit-flex: 1;       /* Safari 6.1+. iOS 7.1+, BB10 */
        -ms-flex: 1;           /* IE 10 */
        flex: 1;               /* NEW, Spec - Firefox, Chrome, Opera */

        @media #{$screen-s-rule-min} {
            float: left;
            width: $width-1of2;
            margin: 0 $margin 20px 0;

            &:nth-child(2n+2) {
                margin-right: 0;
            }
        }

        @media #{$screen-m-rule-min} {
            width: $width-1of4;
            margin: 0 $margin 0 0;

            &:nth-child(2n+2) {
                margin-right: $margin;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &:last-child {
            margin: 0;
        }

        .text {
            text-align: left;
            width: 75%;
            display: inline-block;
            vertical-align: middle;

            @media #{$screen-m-rule-min} {
                width: 100%;
                padding: 0 6px;
                text-align: center;
            }

            @media #{$screen-l-rule-min} {
                width: 75%;
            }
        }
    }

    a {
        @include font-size(17px);
        @include border-radius(5px);
        display: block;
        padding: 12px 0;
        text-align: left;
        border: 1px solid $color-blue;
        text-transform: uppercase;
        font-weight: bold;
        font-family: $link-font-family;
        text-align: center;

        @media #{$screen-m-rule-min} {
            @include font-size(15px);
            padding: 20px 0;
            text-align: center;
            border-width: 2px;
        }

        @media #{$screen-xl-rule-min} {
            @include font-size(17px);
        }

        &.btn--effect {
            html.no-touch & {
                &:hover {
                    border-color: $color-yellow;
                }
            }

            &::before {
                display: none;
            }
        }

        br {
            display: none;

            @media #{$screen-m-rule-min} {
                display: block;
            }
        }
    }

    .icon {
        @include font-size(25px);
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px 10px;
        line-height: 0.5;

        @media #{$screen-m-rule-min} {
            @include font-size(35px);
            margin: 0 0 10px;
        }

        @media #{$screen-l-rule-min} {
            width: 25%;
            padding: 0 0 0 18px;
            margin-bottom: 0;
        }

        @media #{$screen-xl-rule-min} {
            @include font-size(50px);
        }
    }
}
