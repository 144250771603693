#footer {
    position: relative;
    z-index: $zindex-footer;
    margin-top: 40px;
    color: $color-white;
    background-color: $color-blue-dark;

    a {
        color: $color-white;
        text-decoration: none;
    }

    .title {
        color: $color-yellow;
    }
}