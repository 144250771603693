.page-header {
    $blockContext: &;

    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 10px;

    &--back {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 0;
        color: $color-blue;
    }

    &--back-link {
        font-weight: 700;
    }

    &--menu {
        display: grid;
        gap: 30px;

        @media #{$screen-l-rule-min} {
            grid-template-columns: 3fr auto;
        }
    }

    &--content {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    &--grid {
        display: grid;
        row-gap: 30px;
        column-gap: 30px;

        @media #{$screen-l-rule-min} {
            grid-template-columns: 1fr 2fr;
            grid-template-areas: "a b" "a c" "a d";
        }
    }

    &--title {
        @include font-size(30px);
        display: flex;
        margin-bottom: 0;

        @media #{$screen-l-rule-min} {
            @include font-size(40px);
        }

        #{$blockContext}--grid & {
            @media #{$screen-l-rule-min} {
                grid-area: b;
            }
        }
    }

    &--cover {
        #{$blockContext}--grid & {
            @media #{$screen-l-rule-min} {
                grid-area: a;
            }
        }
    }

    &--image {
        width: 100%;
    }

    &--date {
        @include font-size(18px);
        display: flex;
        margin-bottom: 0;
        font-weight: 700;
        color: $color-blue;

        #{$blockContext}--grid & {
            @media #{$screen-l-rule-min} {
                grid-area: c;
            }
        }
    }

    &--summary {
        @include font-size(18px);
        display: flex;
        color: $color-blue;

        #{$blockContext}--grid & {
            @media #{$screen-l-rule-min} {
                grid-area: d;
            }
        }

        p {
            font-weight: 400;
        }
    }
}