@use "sass:math";
// center vertically and/or horizontally an absolute positioned element

@mixin center($xy:xy) {
    @if $xy == xy {
        left: 50%;
        top: 50%;
        bottom: auto;
        right: auto;
        @include transform(translateX(-50%) translateY(-50%));
    }
    @else if $xy == x {
        left: 50%;
        right: auto;
        @include transform(translateX(-50%));
    }
    @else if $xy == y {
        top: 50%;
        bottom: auto;
        @include transform(translateY(-50%));
    }
}

@function calculateBlockWidth($margin: 3%, $blocksNumber: 1) {
    //@return ((100% - ($margin * ($blocksNumber - 1))) / $blocksNumber);
    @return math.div(100% - ($margin * ($blocksNumber - 1)), $blocksNumber);
}

@function calculateBlockWidthMargin($margin: 3%, $blocksNumber: 1) {
    //@return ((100% - (($margin * 3) * ($blocksNumber - 1))) / $blocksNumber);
    @return math.div(100% - (($margin * 3) * ($blocksNumber - 1)), $blocksNumber);
}

// Fix IE and Firebox bug where parent width is ignored
@mixin display-table {
    display: table;
    table-layout: fixed;
    width: 100%;
}



// dynamic font size

@mixin font-size($size, $use-rem: true, $baseSize: $base-font-size) {
    font-size: $size; // fallback

    @if ($use-rem != false) {
        font-size: rem($size, $baseSize);
    }
}



// multiline ellipsis

@mixin ellipsis-multiline($line-clamp: 3) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: $line-clamp;
    -webkit-box-orient: vertical;
}



// icon font smoothing

@mixin font-smoothing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


// icon reset

@mixin icon-reset($speak: true) {
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-decoration: inherit;

    @include font-smoothing;

    @if $speak == false {
        speak: none;
    }
}



// icons that are in the center of their parent container
// NOTE: can use @include center instead if there is no need for IE8 support...

@mixin icon-fullspace($line-height: 1, $text-align: center, $speak: false) {
    position: absolute;
    top: 0;
    left: 0;
    // bottom: 0;
    // right: 0;

    width: 100%;
    height: 100%;

    margin: 0;
    padding: 0;

    line-height: $line-height;
    text-align: $text-align;

    @include icon-reset($speak);
}



// responsive image

@mixin image-responsive($display: null) {
    @if $display {
        display: $display;
    }

    max-width: 100%;
    height: auto;

    // bugfix; check the specific class appended for IE8
    html.#{$class-ie8} & {
        width : auto;
    }
}



// get rid of strange behaviors

@mixin webkit-no-touch-callout {
    -webkit-touch-callout: none;
}
@mixin webkit-no-tap-highlight {
    -webkit-tap-highlight-color: rgba(#000, 0);
}
@mixin webkit-hardware-acceleration($transform: null, $backface: null) {
    @if $transform == true {
        -webkit-transform: translateZ(0);
    }

    @if $backface == true {
        -webkit-backface-visibility: hidden;
    }
}

@mixin text-size-adjust($value: 100%) {
    -webkit-text-size-adjust: $value;
    -moz-text-size-adjust: $value;
    -ms-text-size-adjust: $value;
    -o-text-size-adjust: $value;
    text-size-adjust: $value;
}



// prevent selection

@mixin prevent-selection {
    @include webkit-no-touch-callout;
    @include webkit-no-tap-highlight;

    @include user-select(none);
    @include text-size-adjust(none);

    &::-moz-selection {
        color: transparent;
    }
    &::selection,
    & ::selection {
        color: transparent;
    }
}



// custom selection

@mixin custom-selection($color) {
    // The 2 rule sets need to be kept separated
    &::-moz-selection {
        background: $color;
        color: #fff;
        text-shadow: none;
    }

    &::selection,
    & ::selection {
        background: $color;
        color: #fff;
        text-shadow: none;
    }
}



// get images width and height based on a ratio (width * height)

@mixin getDimensions($ratio, $width: null, $height: null) {
    @if $width {
        width: $width;
        height: round((1 / $ratio) * $width);
    }
    @else if $height {
        width: round($height * $ratio);
        height: $height;
    }
}



// prefixes

@mixin css3-prefix($property, $value) {
    -webkit-#{$property}: #{$value};
    -khtml-#{$property}: #{$value};
    -moz-#{$property}: #{$value};
    -ms-#{$property}: #{$value};
    -o-#{$property}: #{$value};
    #{$property}: #{$value};
}



// border radius

@mixin border-radius($radius: 5px) {
    @include css3-prefix('border-radius', $radius);
}



// box shadow

@mixin box-shadow($args...) {
    @include css3-prefix('box-shadow', $args);
}



// background + relevant text shadow

@mixin background-text-shadow($color: #333, $gradient: null, $gradient-color: null, $shadow-size: 2px, $darken: 10%) {
    background: $color;
    text-shadow: 0 $shadow-size 0 darken($color, $darken);

    @if $gradient-color == null {
        $gradient-color: darken($color, 8%);
    }
    @if $gradient == true {
        @include linear-gradient($color, $gradient-color);
    }
}






// universal hover effect for desktop and mobile devices
// based on Modernizr classes
// WARNING: require <body ontouchstart=""> or a JS touch lib (like FastClick)
// in order for :active to work on any kind of tag

@mixin hover-universal($html-classes...) {
    // html.#{$mobile-class} &:active,
    // html:not(.#{$mobile-class}) &:hover {

    $length: length($html-classes);

    @if $length > 0 {
        $classes: '';

        @for $i from 1 through length($html-classes) {
            $classes: $classes + '.' + nth($html-classes, $i);
        }

        // html.no-touch#{$classes} &:focus,
        // html#{$classes}:not(.touch) &:hover,
        /*html.no-touch#{$classes} &:hover,
            html.touch#{$classes} &:active {
            @content;
        }*/
    } @else {
        // html.no-touch &:focus,
        // html:not(.touch) &:hover,
        /*html.no-touch &:hover,
            html.touch &:active {
            @content;
        }*/
    }
}
