.parallax--wrapper {
    position: relative;
    overflow: hidden;
    
    @include transition(height ease 0.4s);
    
    height: 170px;
    
    @media #{$screen-m-rule-min} {
        height: 260px;
    }
    @media #{$screen-l-rule-min} {
        height: 280px;
    }
    @media #{$screen-xl-rule-min} {
        height: 310px;
    }
    
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        
        background: $color-black;
        z-index: 1;
        
        @include transition(opacity 0.5s ease);
    }
    .illustration {
        width: 100%;
        height: 100%;
        background: url("../images/building.jpg") no-repeat center center $color-gray-light;
        background-size: cover;
    }
}

