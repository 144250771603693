.gm-style-iw {
    li {
        list-style: none;
    }
    .search-result--title {
        margin: 0 auto 10px;
    }
    .link--plus {
        margin-top: 10px;
        display: block;
        @include font-size(14px);
    }
    .link--plus:nth-child(3) {
        margin-top: 0;
    }
    p {
        margin: 0;
        
        br {
            display: none;
        }
    }
}