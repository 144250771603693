.footer--tools {
    $margin: 0;
    $width-1of4: calculateBlockWidth($margin, 4);

    @media #{$screen-s-rule-min} {
        width: 50%;
    }
    @media #{$screen-l-rule-min} {
        width: $width-1of4;
    }
    &.block-1of4 {
        @media #{$screen-s-rule-min} {
            margin: 0 auto;
            float: none;
        }
        @media #{$screen-l-rule-min} {
            margin: 0;
            float: left;
        }
    }
    ul {
        padding: 0;
        margin: 0;
    }
    .block, 
    .footer--tools-btn {
        padding: 20px;
        background: $color-blue;
        
        @media #{$screen-m-rule-min} {
            padding: 25px;
        }
    }
    .block {
        margin: 0 0 10px;
        
        @media #{$screen-m-rule-min} {
            margin: 0 0 20px;
        }
        @media #{$screen-xl-rule-min} {
            margin: 0 0 30px;
        }
        
        li { 
            font-weight: normal;
            margin: 0 0 15px;
            
            &.niv1 {
                margin: 0;
            }
            
        }
        .title {
            margin: 0 0 50px;
        }
    }
    .footer--tools-btn {
        display: block;
        text-align: center;
        margin: 0 0 10px;
        
        @media #{$screen-m-rule-min} {
            margin: 0 0 20px;
        }
        
        &:last-child {
            margin: 0;
        }
    }
    
}

