// Styles from: https://github.com/Grafikart/CSS3-Loaders

@mixin custom-loader($color, $size, $center: null) {

    @if ($center == true) {
        position: absolute;
        display: inline-block;
        top: 50%;
        left: 50%;
        margin-top: -(calc($size / 2));
        margin-left: -(calc($size / 2));

        width: $size;
        height: $size;

        vertical-align: middle;
    }

    pointer-events: none;

    background: url('../images/assets/loader.gif') center center no-repeat transparent;

    html.cssanimations & {
        // background: none;

        // @include loader-spinner($color-white, $size, null, .7s);
        // @include loader-circles($color-white, $size);
    }
}


@mixin loader-spinner($color, $size, $duration: .6s) {
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(#000, .1), 2px 1px 0px $color;

    @include animation(spin $duration linear infinite);
}


@mixin loader-circles($color, $size, $duration: 1s, $border-width: 2px) {
    border-radius: 50%;
    border: $border-width solid transparent;
    border-top-color: $color;

    @include animation(spin $duration linear infinite);

    &:before,
        &:after{
        content:'';
        position: absolute;
        border-radius: 50%;
        border: $border-width solid transparent;
    }

    &:before{
        $circle-1-position: getEven(calc($size / 10));

        top: $circle-1-position;
        left: $circle-1-position;
        bottom: $circle-1-position;
        right: $circle-1-position;

        border-right-color: $color;
        opacity: .8;
        @include animation(spin ($duration * 10) linear infinite);
    }

    &:after{
        $circle-2-position: getEven(calc($size / 4));

        top: $circle-2-position;
        left: $circle-2-position;
        bottom: $circle-2-position;
        right: $circle-2-position;

        border-bottom-color: $color;
        opacity: .5;
        @include animation(spin ($duration * 5) linear infinite);
    }
}


@mixin loader-quart($color, $size, $duration: 1s, $border-width: 6px) {
    border-radius: $size;
    border: $border-width solid rgba($color, .4);

    &:after{
        content:'';
        position: absolute;
        top: -$border-width;
        left: -$border-width;
        bottom: -$border-width;
        right: -$border-width;
        border-radius: $size;
        border: $border-width solid transparent;
        border-top-color: $color;

        @include animation(spin $duration linear infinite);
    }
}


@mixin loader-bars($color, $width: 40px, $height: 30px) {
    $bar-column-width: round(calc($width / 8));
    $bar-width: $bar-column-width * 2;
    $bar-spacing: $bar-column-width;

    &:before,
        &:after,
        span{
        content:'';
        display: block;
        position: absolute;
        left: 0px;
        top: 0;
        width: $bar-width;
        height: $height;
        background-color: $color;
        @include animation(grow 1s linear infinite);
    }

    &:after{
        left: $bar-width + $bar-spacing;
        @include animation-delay(-.66s);
    }

    span{
        left: ($bar-width + $bar-spacing) * 2;
        @include animation-delay(-.33s);
    }
}



.custom-loader--wrapper {
    $wrapper-size: 90px;
    $transition-duration: .3s;

    z-index: $zindex-loader;

    position: absolute;

    body > & {
        position: fixed;
    }

    top: 50%;
    left: 50%;
    margin-top: -(calc($wrapper-size / 2));
    margin-left: -(calc($wrapper-size / 2));

    width: $wrapper-size;
    height: $wrapper-size;

    background: $color-blue;
    background: rgba($color-blue, .9);
    @include border-radius(calc($wrapper-size / 2));

    // WARNING: hidden at first
    visibility: hidden;
    opacity: 0;

    @include transform(translateZ(0));

    @include transition(
    opacity $transition-duration linear,
    visibility 0s $transition-duration
    );

    &.active {
        visibility: visible;
        opacity: 1;

        @include transition(
        opacity $transition-duration linear,
        visibility 0s 0s
        );
    }
}

.custom-loader {
    $spinner-color: $color-white;
    $spinner-size: 50px;

    @include custom-loader($spinner-color, $spinner-size, $center: true);

    html.cssanimations & {
        background: none;

        &.type--spinner {
            @include loader-spinner($spinner-color, $spinner-size, $duration: .7s);
        }
        &.type--circles {
            @include loader-circles($spinner-color, $spinner-size);
        }
        &.type--quart {
            @include loader-quart($spinner-color, $spinner-size, $border-width: 4px);
        }
        &.type--bars {
            $width: 40px;
            $height: 30px;

            width: $width;
            height: $height;
            margin-left: -(calc($width / 2));
            margin-top: -(calc($height / 2));

            @include loader-bars($spinner-color, $width, $height);
        }
    }
}
