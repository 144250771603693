// Settings for NEAT grids

// $column: 90px;
// $gutter: 30px;
// $column: modular-scale(3, 1em, $golden) !default;
// $gutter: modular-scale(1, 1em, $golden) !default;

$layout-width-min: 320px; // WARNING: we won't deal with smartphones smaller than this
$layout-width-max: 1200px;

$img-width-max: 1400px;

$grid-columns: 12; // WARNING: make sure this number can be divided by 3 and 4
$max-width: $layout-width-max; // use a $grid-colums muliple
