.box-infos {
    width: 100%;
    padding: 40px 30px;
    border-radius: 8px;
    color: $color-white;
    background-color: $color-blue;

    > *:nth-last-child(1) {
        margin-bottom: 0;
    }

    &--title {
        @include font-size(16px);
        margin-top: 0;
        font-weight: 700;
        color: $color-white;
    }

    &--map {
        width: 100%;
        height: 300px;
        margin-bottom: 20px;
        border: 0;
    }
}