.list-icons {
    $blockContext: &;

    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    gap: 20px;
    margin: 0;
    padding: 0;
    list-style: none;

    @media #{$screen-m-rule-min} {
        &-col-3 {
            grid-template-columns: auto auto auto;
        }

        &-col-4 {
            grid-template-columns: auto auto auto auto;
        }

        &-col-5 {
            grid-template-columns: auto auto auto auto auto;
        }
    }

    &--link {
        @include font-size(14px);
        display: flex;
        align-items: center;
        gap: 8px;
        text-decoration: none;

        @media #{$screen-m-rule-min} {
            @include font-size(15px);
            align-items: flex-end;
        }
    }

    &--icon {
        display: block;
        position: relative;

        &:after {
            @include transform(rotate(42deg));
            content: "";
            display: block;
            width: 30px;
            height: 30px;
            position: absolute;
            top: 11px;
            left: 17px;
            border-left: 1px solid $color-gray-light;
            background-color: $color-white;

            @media #{$screen-m-rule-min} {
                width: 50px;
                height: 50px;
                top: 25px;
                left: 25px;
            }

            @media #{$screen-l-rule-min} {
                width: 80px;
                height: 80px;
                // top: 40px;
                // left: 45px;
            }
        }

        .icon {
            @include font-size(30px);
            @include transition(all ease 0.5s);
            display: block;

            @media #{$screen-m-rule-min} {
                @include font-size(45px);
            }

            @media #{$screen-l-rule-min} {
                @include font-size(50px);
            }

            #{$blockContext}--link:focus &,
            #{$blockContext}--link:hover & {
                transform: scale(1.1);
            }
        }
    }

    &--label {
        position: relative;
        z-index: 4;
        font-weight: 700;
        color: $color-title;
        text-transform: uppercase;
    }
}
