.org-chart--first {
    border-left: 1px solid $color-blue;
    padding-left: 0;
    list-style-type: none;
    
    @include font-size(18px);
    
    .org-chart--profile {
        margin: 0 0 10px;
        display: inline-block;
        vertical-align: top;
        width: 76%;
        background: #fff;
        
        &:before { // yellow circle
            content: "";
            display: inline-block;
            background: #fff;
            border: 4px solid $color-yellow;
            width: 20px;
            height: 20px;
            margin: 4px 0 0 -10px;
            box-shadow: 0px 0px 0px 4px #fff;

            @include border-radius(50%);
        }
        
        .icon-triangle {
            display: inline-block;
            background: $color-yellow;
            width: 20px;
            height: 20px;
            margin: 4px 0 0 -24px;
            box-shadow: 0 0 0 4px #fff;
            cursor: pointer;

            @include border-radius(50%);
            @include transition(all ease 0.35s);
            
            .no-touch &:hover {
                background: $color-blue;
                color: $color-yellow;
            }            
            &:before {
                color: $color-blue;

                @include font-size(20px);
            }
            .no-touch &:hover:before {
                color: $color-yellow;
            }
        }  
        .open {
            @include transform(rotate(180deg));
            @include transition(all ease 0.35s);
        }
    }    
    .fonction {
        width: 80%;
        font-style: italic;
        margin: 0 0 0 34px;
        padding: 0;
        display: inline-block;
        vertical-align: top;
        
        @include font-size(14px);
        
        @media #{$screen-l-rule-min} {
            @include font-size(18px);
        }
        
        &:first-of-type {
            margin: 0;
            padding-left: 20px;
        }
    }
    .nom {
        color: $color-blue;
        font-weight: 400;
        padding: 8px 0 0 34px;
    }
    .org-chart--second {
        padding-left: 0;
        list-style-type: none;
        
        li {
            padding-top: 20px;
            position: relative;
            
            &:last-of-type {
                margin-bottom: 40px;
            }
        }
        .branch {
            width: 10%;
            height: 1px;
            display: inline-block;  
            border-top: 1px solid $color-blue;
            
            &.double-branch {
                width: 20%;
            }
        }        
        
        .org-chart--third {
            border-left: 1px solid $color-blue;
            padding: 60px 0 0 0;
            margin: -44px 0 0 10%;
            list-style-type: none;
            display: none;
            
            & li .branch {
                border-top: 1px solid $color-blue !important;
            }
            & li:last-child .branch {
                border-top: 0 !important;
            }
        }
        
        & li:last-child {
            margin-left: -1px;
            border-left: 1px solid #fff;
            
            & .branch {
                border-top: 0;
            }
            &:before {
                content: "";
                width: 8%;
                height: 100%;
                margin-left: -1px;
                position: absolute;
                top: 0;
                left: 0;
                background: url('../images/bg-org-bottom.jpg') left top no-repeat;
            }
        }
        &.alone li:last-child {
            border: none;
            
            &:before {
                background: none;
                margin-left: -2px;
            }
            
            & .branch {
                border-top: 1px solid $color-blue;
            }
        }
    }
}