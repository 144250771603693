#colorbox {

    // fix for position fixed on older Android devices
    html.is-android & {
        @include backface-visibility(hidden);
    }
}

#cboxContent {
    background: $color-white;
}

#cboxLoadingGraphic {
    background: url('../images/assets/loader.gif') center center no-repeat transparent;
}
