.search-result--block.elected--header {
    margin: 0;
    float: none;
    clear: both;
    
    h3 {
        margin: 0;
    }
    p {
        @include font-size($base-font-size);
        padding-left: 15px;
    }
}
.elected--box {
    position: relative;
    width: 100%;
    float: left;
    
    @media #{$screen-s-rule-min} {
        width: 50%;
    }    
    @media #{$screen-l-rule-min} {
        width: 33%;
    }
    
    .ratio {
        width: 100%;
        padding-top: 100%;
    }
    .elected--menu {
        z-index: 10;
        position: absolute;
        left: -999em;
        text-align: left;
        background: $color_blue;
        color: #fff;
        font-family: $base-font-family;
        padding: 12px;
        
        h3 {
            color: #fff;
            border-color: #fff;
            padding-bottom: 6px;
        }
        ul {
            padding-top: 20px;
            padding-left: 0;
        }
    }
    .no-touch & {
        &:hover .elected--menu {
            left: auto;
        }
    }
} 
.elected--content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    &:after { 
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba($color-blue, 0);
        
        z-index: 1;
        
        @include transition(all ease 0.35s);
    }
    html.no-touch & {
        &:hover {
            .caption {
                background: $color-blue;
            } 
        }
    }
    img {
        width: 100%;
        height: auto;
    }
    a {
        text-decoration: none;
    }
    .caption {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px;
        color: $color-yellow;
        background: rgba($color-blue, 0.8);  
        
        z-index: 2;
        
        @include transition(all ease 0.5s);
        
        h3, p {
            padding: 0;
            margin: 0;
            color: #fff;
        }
        h3 {
            @include font-size(16px);
        }
    }
    .title {
        color: $color-yellow;
        margin: 0 0 8px;
        padding: 0;
        font-weight: 900;
        font-family: $base-font-family;
        
        @include font-size(14px);
                
        @media #{$screen-m-rule-min} {
            @include font-size(13px);
        }
        @media #{$screen-l-rule-min} {
            @include font-size(16px);
        }
    }
    p {
        margin: 0;
        line-height: 1;
    }
    .elected--link {
        display: block;
        height: 100%;
        position: relative;
        z-index: 3;
    } 
        
    &.nonactive-filter img {
        filter: gray; /* IE 6-9 */
        filter: url("../images/gray.svg#grayscale");
        filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='saturate' values='0'/></filter></svg>#grayscale");
        @include filter(grayscale(100%));
    }
    
    &.active-filter img {
        filter: none; /* IE 6-9 */
        @include filter(grayscale(0%));
    }
}
