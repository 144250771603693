.bx-wrapper {

    .bx-loading {
        $spinner-size: 50px;
        $spinner-color: $color-gray;

        z-index: 1;

        position: absolute;
        display: inline-block;
        top: 50%;
        left: 50%;
        margin-top: -(calc($spinner-size / 2));
        margin-left: -(calc($spinner-size / 2));

        width: $spinner-size;
        height: $spinner-size;

        vertical-align: middle;

        background: url('../images/assets/loader.gif') center center no-repeat transparent;

        html.cssanimations & {
            background: rgba($color-white, .6);

            // @include loader-spinner($spinner-color, $spinner-size, null, .7s);
            @include loader-circles($spinner-color, $spinner-size);
        }
    }
}