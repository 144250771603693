/* Legacy messy components */
@import "blocks";
@import "box";
@import "title";
@import "direct-access";
@import "article";
@import "block-event";
@import "block-documentation";
@import "block-key-number";
@import "testimony";
@import "block-syctom-img";
@import "block-share";
@import "block-number";
@import "elected";
@import "block-search";
@import "org-chart";
@import "news";
@import "directory";
@import "training";
@import "sitemap";
@import "sondage";
/* New clean components */
@import "list-icons";
@import "documentation";
@import "zoom";
@import "page-login";
@import "page-header";
@import "page-menu";
@import "page-content";
@import "page-aside";
@import "box-dialog";
@import "box-infos";
@import "box-mag";
@import "box-testimony";
@import "card";
@import "jobs";
@import "document";