.block--share {
    position: relative;

    .btn--share {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        padding: 0;
        border: 0;
        color: $color-blue;
        text-decoration: none;
        background-color: transparent;

        .icon {
            @include font-size(22px);
            display: flex;
        }
    }
}

.block--share-icons {
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    z-index: 10;
    margin: 0;
    padding: 6px;
    background-color: $color-white;

    li {
        list-style: none;
    }

    a {
        display: flex;
        color: $color-blue;
        text-decoration: none;

        .icon {
            @include font-size(30px);
        }
    }
}