.page-aside {
    display: grid;
    gap: 30px;

    @media #{$screen-m-rule-min} {
        grid-template-columns: 1fr 1fr;
    }

    > * {
        margin-top: 0;
        margin-bottom: 0;
    }
}