.accordion {
    list-style: none;
    width: 100%;
    padding: 0;       

    .switch, .switch2, .switch3, .switch4, .switch5, .switch6 {
        position: absolute;
        top: 10px;
        right: 20px;
        display: block;
        height: 36px;
        cursor: pointer;
        color: $color-blue;
        @include transition(all ease-out 0.2s);
        @include font-size(36px);

        &:hover {
            color: $color-yellow;
        }
    }
    .switch {
        top: 30px;
        right: 0;
    }
}
.accordion ul {
    list-style: none;
    margin: 30px 0;
    padding: 0;
}
.accordion li {
    position: relative;
    margin: 0;
    padding: 40px 40px 15px 0;
    border-bottom: 2px solid $color-bg;
    background: #fff;
    text-transform: uppercase;
    @include font-size(17px);   
}
.accordion li li {
    font-weight: 400;
    padding: 15px 60px 15px 20px;
    border-bottom: 2px solid #fff;
    background: $color-bg;
    text-transform: none;
    @include font-size(15px);
}
.accordion li li li {
    padding: 15px 60px 15px 20px;
    border-bottom: 2px solid $color-bg;
    background: #fff;
    text-transform: none;
    @include font-size(14px);
}
.accordion li li li li {
    padding: 15px 60px 15px 20px;
    border-bottom: 2px solid #fff;
    background: $color-bg;
    text-transform: none;
    @include font-size(13px);
}
.accordion a {
    text-decoration: none;
    color: $color-blue;

    &:hover {
        color: $color-yellow;
    }
}