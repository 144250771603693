.search--title {
    @include font-size(22px);
    margin-top: 0;
    padding-left: 16px;
    border-left: 4px solid $color-yellow;

    &::after {
        display: none;
    }
}
.search--label {
    @include font-size(14px);
    box-sizing: content-box;
    display: inline-block;
    width: inherit;
    vertical-align: top;
    margin: 0 5px 5px 0;
    padding-top: 8px;
    color: $color-text;

    @media #{$screen-m-rule-min} {
        width: 20%;
    }
    #col--main & {
        display: block;
        width: inherit;

        @media #{$screen-l-rule-min} {
            display: inline-block;
            width: 20%;
        }
    }
}
.search--bar-block {
    text-align: center;
    padding: 12px 0 20px 0;

    .search--bar {
        width: 39%;
        display: inline-block;
        border-bottom: 1px solid #999;
    }
    .search--bar-text {
        width: 4%;
        display: inline-block;
        padding: 0 16px;
        box-sizing: content-box;
        color: #666;

        @include font-size(12px);
    }
}
.search--filters {
    padding: 8px 0;

    .search--words-block {
        display: inline-block;
        width: 100%;
        
        @media #{$screen-l-rule-min} {
            width: 75%;
        }  
        input.field--search-text {
            
            @media #{$screen-m-rule-min} {
                display: inline-block;
                width: 40%;
                margin-right: 20px;
            }
            @media #{$screen-l-rule-min} {
                width: 30%;
            }
            #col--main & {
                display: block;
                width: 100%;
            }
        }
        .field--search-submit,
        .a {
            display: block;
            margin: 0 10px 10px 0;
            
            @media #{$screen-s-rule-min} {
                display: inline-block;
                vertical-align: middle;
            }
        }
        .tx-solr-component-select-box select {          
            width: 100%;
            margin-bottom: 0;
            
            @media #{$screen-l-rule-min} {
                width: 30%;
            }
        }
        .field--radio {
            display: inline-block;

            & label {
                width: inherit;
                margin-right: 40px;
                color: $color-text;
            }
            &:last-of-type label {
                margin-right: 0;
            }
        }
    }
}
.search--hashtag-word {
    @include font-size(14px);
    display: inline-flex;
    padding: 6px 8px;
    border: 1px solid $color-blue-dark;
    border-radius: 5px;
    color: $color-blue-dark;
    text-decoration: none;
    background-color: $color-white;
}
.tab-current .btn--effect {
    background-color: $color-blue;
    color: $color-white;
    border-color: $color-blue;
}
.tx-solr-facet-component {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
        display: inline-block;
    }
}
#tx-solr-facets-in-use {
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
        }
    }
}
#tx-solr-map {
    height: 700px;

    [data-icon]:before {
        display: none;
    }
}
.decheteries--legend-text {
    margin: 0 2em 0 .2em;
}