.skin-openclose {
    width: 100%;
    padding: 0;
    position: relative;
    text-align: left;
    
    @include transition(all ease-out 0.2s);
    
    .title{
        margin: 0;
        padding: 15px 50px 15px 0;
        font-size: 1.286em;
        font-weight: 400;
        cursor: pointer;
        line-height: inherit;
        color: $color-blue;
        border-bottom: 1px solid $color-blue;
        display: flex;
        position: relative;
        
        @media #{$screen-l-rule-min} {
            padding: 20px 6% 20px 0;
        }
        
        &:hover {
            .button-openclose {
                &:before, 
                &:after{
                    color: $color-yellow;
                }
            }
        }
        
    }
    .content {
        padding: 20px 20px 30px;
        display:none;
    }
}
.button-openclose {
    position: absolute;
    display: block;
    width: 31px;
    height: 31px;
    margin: 2px;
    right: 10px;
    top: 28px;
    margin-top: -17px;
    -moz-transition: all ease-out 0.2s;
    -webkit-transition: all ease-out 0.2s;
    -o-transition: all ease-out 0.2s;
    -ms-transition: all ease-out 0.2s;
    transition: all ease-out 0.2s;

    
    &:before {
        @extend %icomoon;
        @extend .icon-angle-down;
        @include font-size(30px);
        @include transition(all ease-out 0.3s);
        position: absolute;

        .open & {
            @include transform(rotate(180deg));
            @include transition(all ease-out 0.3s);
        }
    }
    
}
.tab--content .block-openclose {
    clear: both;
}