.card {
    $blockContext: &;

    display: flex;
    width: 100%;

    &--link {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
    }

    &--cover {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &--player {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: .7;

        &::before {
            @include font-size(90px);
            color: $color-white;
        }

        #{$blockContext}--link:focus &,
        #{$blockContext}--link:hover & {
            opacity: 1;
        }
    }

    &--caption {
        width: 100%;
        position: absolute;
        bottom: 0;
        padding: 15px 20px;
        background-color: $color-blue-dark;

        #{$blockContext}--link:focus &,
        #{$blockContext}--link:hover & {
            background-color: $color-blue;
        }
    }

    &--title {
        @include font-size(14px);
        margin: 0;
        padding: 0;
        color: $color-white;

        @media #{$screen-m-rule-min} {
            @include font-size(16px);
        }

        #{$blockContext}-featured & {
            @include font-size(18px);

            @media #{$screen-m-rule-min} {
                @include font-size(22px);
            }
        }
    }
}