$margin: 2%;
$width-1of2: calculateBlockWidthMargin($margin, 2);
$width-1of3: calculateBlockWidthMargin($margin, 3);

.btn--search {
    $blockContext: &;

    @include transition (all ease 0.5s);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 5px;
    right: 60px;
    padding: 0;
    border: 0;
    background-color: transparent;

    @media #{$screen-header-rule-min} {
        width: 44px;
        height: 44px;
        border-radius: 100%;
        position: static;
        color: $color-white;
        background-color: $color-yellow;
    }

    &:focus,
    &:hover {
        background-color: transparent;
    }

    &-icon {
        @include font-size(24px);
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-yellow;

        @media #{$screen-header-rule-min} {
            color: $color-white;
        }

        #{$blockContext}:focus &,
        #{$blockContext}:hover & {
            color: $color-yellow;
        }
    }
}

.block--search {
    display: none;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    border-bottom: 1px solid $color-gray-light;
    background-color: $color-white;

    @media #{$screen-header-rule-min} {
        top: 70px;
    }

    label {
        display: inherit;
        font-weight: normal;
        font-style: normal;
        text-align: center;
        text-transform: uppercase;
        font-family: $heading-font-family;

        @include font-size(17px);

        @media #{$screen-header-rule-min} {
            @include font-size(22px);
        }
    }
    .field--container {
        text-align: center;
    }
    fieldset {
        border: none;
        padding: 25px 0;
        background: none;

        @media #{$screen-header-rule-min} {
            padding: 25px 20px;
        }
    }
    input[type="text"] {
        width: 75%;
        margin: 0 2% 0 0;
        display: inline-block;
        vertical-align: top;
        color: $color-text;
        border: none;
        border-bottom: 2px solid $color-yellow;
        border-radius: 0;
        box-shadow: none;
        background: none;

        @media #{$screen-header-rule-min} {
            width: 88%;
            vertical-align: middle;
        }
        @media #{$screen-xl-rule-min} {
            width: 88%;
            vertical-align: middle;
        }
    }
}
.submit--search {
    $width: 40px;

    display: inline-block;
    vertical-align: bottom;
    width: $width;
    height: $width;
    line-height: $width;
    color: $color-white;
    text-align: center;
    border: none;
    background: $color-yellow;

    @include border-radius($width);

    @media #{$screen-header-rule-min} {
        $width: 70px;

        width: $width;
        height: $width;
        line-height: $width;

        @include border-radius($width);
    }
    @media #{$screen-xl-rule-min} {
        $width: 98px;

        width: $width;
        height: $width;
        line-height: $width;

        @include border-radius($width);
    }
    .icon {
        padding-top: 3px;
        display: block;

        @include font-size(20px);

        @media #{$screen-header-rule-min} {
            @include font-size(40px);
        }
    }
}

// RESULTS
.search-result--number {

    @include font-size(20px);

    // Page recherche Territoires
    &.search-result--number--absolute {
        position: absolute;
        @include transform(translateY(-92px));
    }
}
.search-result {
    min-width: 290px;

    &.framed {
        display: block;
        clear: both;
        padding: 0 0 16px 0;
        margin-top: 0;
        background-color: transparent;

        @include border-radius(0);

        .mag--img {
            margin: 5%;
        }
        .mag--text {
            width: 52%;
            padding-right: 2%;

            .title {
                margin: 10% 0 10px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            p {
                padding-left: 0;
            }
        }
    }
    ol {
        padding: 0;
        margin: 0;
    }
    li {
        display: block;
    }
}    
.block-title-center {
    text-align: center; 
    width: 100%; 
    position: absolute;
}
.search-result--title {
    padding: 10px 16px;
    background: $color-blue;
    text-align: center;
    color: $color-white;
    display: table;
    margin: 0 auto;
    text-transform: uppercase;

    @include font-size(12px);

    @media #{$screen-m-rule-min} {
        @include font-size(15px);
    }
}
.search-result--block {
    width: 96%;
    float: left;
    border-radius: 0;
    background: $color-white;
    color: $color-text; 
    margin-top: $margin;
    margin-left: $margin;

    @media #{$screen-header-rule-min} {
        width: $width-1of3;
        margin-top: $margin;
        margin-left: $margin;
    }

    .job-offers & {
        @media #{$screen-xl-rule-min} {
            width: $width-1of3;
        }
    }

    @include transition(all ease-out 0.2s);

    p {
        padding: 10px 20px;
        margin: 0;
        font-weight: 500;

        @include font-size(13px);

        &.day, &.month {
            padding: 2px;
            @include font-size(14px);

            @media #{$screen-header-rule-min} {
                @include font-size(25px);
            }
        }
    }
    &:last-child {
        margin-right: 0;
    }
    .icon-triangle {
        width: 40px;
        color: $color-blue;
        cursor: pointer;
        float: right;

        @include font-size(40px);        
        @include transition(all ease-out 0.2s);

        .no-touch &:hover {
            color: $color-yellow;
        }
    }
    .open {
        @include transform(rotate(180deg));
    }
    .search-result--answer {
        z-index: 10;
        position: absolute;
        background: $color-blue;
        color: $color-white;
        font-family: $base-font-family;
        padding: 12px;
        display: none;
        text-align: left;

        @include font-size(13px);
    }

    .search-result--subtitle {
        font-weight: bold;
        @include font-size(16px);

        & a {
            color: #fff;
        }
        &:first-letter {
            text-transform: uppercase;
        }
    }
    .search-result--answer-link {
        text-align: right;
        padding: 0;

        a {                
            color: $color-white;
        }
    }    
    .map--solr & {
        margin: 16px 0 0 16px;
        min-width: 300px;

        ul li a {
            color: $color-white;
        }
    }        
    &.active-filter {
        @include filter(opacity(50%));        
        @include transition(all ease-out 0.2s);
    }
    .bg--img-installation {
        background-image: url('../images/bg-img-installation.jpg');
        height: 166px;
        background-size: cover;
    }
    .bg--img-page, .bg--img-page-syctom, .bg--img-page-actu, .bg--img-page-collectivite, .bg--img-page-green {
        height: 150px;
        background-size: cover;
        background-position: 50% 50%;

        &.bg--img-page-verbatim {
            height: 300px;
            position: relative;
        }
        &.bg--img-page-temoignage {
            height: 190px;
            position: relative;
        }
    }
    .bg--img-page {
        background-image: url('../images/bg-img-page.jpg');
    }
    .bg--img-page-syctom {
        background-image: url('../images/banner-syctom.jpg');
    }
    .bg--img-page-actu {
        background-image: url('../images/banner-actu.jpg');
    }
    .bg--img-page-collectivite {
        background-image: url('../images/banner-collectivite.jpg');
    }
    .bg--img-page-green {
        background-image: url('../images/banner-green.jpg');
    }
    .search-result--padding {
        padding: 12px;

        &.bg--blue {
            h4 {
                padding-left: 0;
                color: $color-white;
                font-family: $heading-font-family;
                font-style: normal;
            }
            
            .link--icon {
                text-decoration: underline;
                
                .icon {
                    color: $color-white;
                }
                .text {
                    text-decoration: underline;
                }
            }
        }
    }
    .search-result--padding-left {
        padding-left: 20px !important;
    }
    .ul--rte {
        padding: 10px 0 0 20px;

        .result--search-elected & {
            padding: 0;
        }
    }
    &.box--search-elected {

        h3 {
            padding-left: 20px !important;
            margin-bottom: 5px;
        }
        .date {
            .solidarite & {
                display: inline-block;
                color: $color-white;
                padding: 10px 20px;
                margin-left: 20px;
                background-color: $color-map-subtitle;

                @include font-size(15px);
            }
            
        }
        p {
            @include font-size($base-font-size);
            padding-top: 3px;
        }
    }
    .date {
        .solidarite & {
            display: inline-block;
            color: $color-white;
            padding: 10px 20px;
            margin-left: 20px;
            background-color: $color-map-subtitle;

            @include font-size(15px);
        }
            
    }
    p {
        @include font-size($base-font-size);
        padding-top: 3px;
    }
}
// PAGER
.search-result--pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding-top: 30px;

    .pager {
        @include font-size(14px);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 46px;
        height: 46px;
        border: 1px solid $color-gray-verylight;
        color: $color-blue;
        font-weight: 700;
        text-align: center;
        text-decoration: none;
        background-color: $color-white;

        @media #{$screen-m-rule-min} {
            // @include font-size(30px);
            // width: 42px;
            // height: 48px;
        }
    }
    .selected, .selected:hover {
        color: $color-white;
        background-color: $color-blue;
        cursor: inherit;
    }
    .pager--dots {
        display: inline-block;
        text-align: center;
        margin: 0 14px;
        color: $color-text;

        @include font-size(30px);
    }
    .pager--prev, .pager--next {
        @extend .pager;
        @include font-size(22px);

        @media #{$screen-m-rule-min} {
            //@include font-size(30px);
        }
    }
    .pager--prev .text:before {
        @extend %icomoon;
        @extend .icon-angle-left;
    }
    .pager--next .text:before {
        @extend %icomoon;
        @extend .icon-angle-right;
    }
}

// AUTOCOMPLETE
.ui-autocomplete {
    z-index: 1000;
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
    border: none;
    background: $color-border;
    color: #000;
}
.ui-menu .ui-menu-item a.ui-state-hover,
.ui-menu .ui-menu-item a.ui-state-active {
    margin: 0;
}
