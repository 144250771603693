.home.syctom .sections .section:nth-child(1) {
    margin-bottom: 50px;

    @media #{$screen-header-rule-min} {
        margin-bottom: 64px;
    }
}

.home.syctom .sections .section:nth-child(1) .list-icons {
    padding-bottom: 0;
}