.swiper-pagination-bullet {
    $width: 20px;
    
    width: $width;
    height: $width;
    opacity: 1;
    position: relative;
    box-shadow: inset 0 0 0 2px $color-blue;
    background: #fff;
    
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: $width;
        height: $width;
        box-shadow: inset 0 0 0 2px $color-blue;
        
        @include border-radius($width);
        @include transform(scale3d(0, 0, 0));
        @include transition(all ease 0.5s);
    }
    &:hover {
        &:after {
            box-shadow: inset 0 0 0 22px $color-blue;
            
            @include transform(scale3d(1, 1, 1));
        }
    }
}
.swiper-pagination-bullet-active {
    &:after {
        box-shadow: inset 0 0 0 22px $color-blue;
        
        @include transform(scale3d(1, 1, 1));
    }
}
.swiper-button-next, .swiper-button-prev {
    background: none;
}
.swiper-button-prev {
    left: 25px;
}
.swiper-button-next {
    right: 25px;
}
.swiper-wrapper {
    padding: 0;
    margin: 0;
    
    li {
        list-style: none;
    }
}
.swiper_imgVideo {    
    width: 100%;
    padding: 0 30px;
    position: relative;
    margin: auto;
   
    .gallery--pager {
        .swiper-slide {
            width: 25% !important;
            height: 100%;
            padding: 0 4px;
        }
    }
    .swiper-controls {
        .icon {
            @include font-size(40px);
        }
    }
    .swiper-button-next, .swiper-button-prev {
        top: 50%;
    }
    .swiper-button-next {
        right: 6px;
    }
    .swiper-button-prev {
        left: -6px;
    }
    .swiper-slide {
        aspect-ratio: 3 / 2;
        background-size: cover;
        background-position: center center;
        
        .content--figure {
            display: block;
            height: 200px;
            
            @media #{$screen-l-rule-min} {
                height: 300px;
            }
            @media #{$screen-xl-rule-min} {
                height: 400px;
            }

            .block-1of2 & {
                
                @media #{$screen-s-rule-min} {
                    height: 300px;
                }
                @media #{$screen-m-rule-min} {
                    height: 275px;
                }
                @media #{$screen-l-rule-min} {
                    height: 170px;
                }
                @media #{$screen-xl-rule-min} {
                    height: 217px;
                }
                figure {
                    position: inherit;
                }
            }
        }
    }
}
.swiper-container {
    
    figure {
        margin: 0;
    }
}
.gallery--pager {
    padding: 20px 0 0;
}