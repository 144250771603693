.bx-wrapper {

    .bx-pager {
        z-index: 1;

        position: absolute;
        bottom: 0;
        width: 100%;

        text-align: center;
        color: $color-gray;

        @include font-size(14px);

        @media #{$screen-l-rule-min} {
            bottom: 10px;
        }

        .bx-pager-item {
            display: inline-block;
            *zoom: 1;
            *display: inline;

            + .bx-pager-item {

                a {
                    margin-left: 4px;
                }
            }
        }

        &.bx-default-pager a {
            $size: 10px;
            $border-size: 1px;
            $transition-duration: .35s;

            position: relative;
            display: block;
            width: 30px;
            height: 4px;

            background: $color-white;

            outline: 0;
            text-indent: -9999px;
            text-decoration: none;

            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
            filter: alpha(opacity=60);
            opacity: .6;

            @include transition(opacity $hover-transition-duration ease);

            &.active,
            html.no-touch &:hover,
                html.touch &:active {
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
                filter: alpha(opacity=100);
                opacity: 1;
            }
        }
    }

    .bx-controls.bx-has-controls-auto.bx-has-pager {

        .bx-pager {
            text-align: left;
            width: 80%;
        }
    }
}