// Avoid unwanted styles before slider init ('bx-wrapper' class isn't added before init)
.slider--reset,
.slider--illustration {
    margin: 0 auto;
    padding: 0;
    list-style: none;
}

// Hide items during page load, but only when the browser is js-compatible
// WARNING: make sure to show them back on onSliderLoad()
html.js {

    .slider--reset,
    .slider--illustration {

        li {
            opacity: 0;
            @include transition(opacity .5s ease);
        }
    }

    // Class 'init-complete' is added with js
    .bx-wrapper.init-complete {

        .slider--reset,
        .slider--illustration {

            li {
                opacity: 1;
            }
        }
    }
}

// Scaffolding
.bx-wrapper {
    position: relative;
    margin: 0 auto;
    padding: 0;
    *zoom: 1;


    .bx-viewport {
        position: relative;
        width: 100%;

        // fix other elements on the page moving (on Chrome)
        @include transform(translateZ(0));
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
    }
}

// Overload
.bx-wrapper.init-complete .bx-controls-direction {

    .bx-prev,
    .bx-next {
        top: 50%;
    }
}

