.banner--link {
    position: relative;
    display: block;

    .icon {
        @include font-size(60px);
        @include center(xy); 
        position: absolute;
        top: 40%;
        left: 40%;
        color: $color-white;
        opacity: 0.8;

        @media #{$screen-m-rule-min} {
            @include font-size(80px);
        }
        @media #{$screen-l-rule-min} {
            @include font-size(120px);
        }
        @media #{$screen-xl-rule-min} {
            @include font-size(180px);
        }
    }

    &:hover:before {
        @extend .icon-business;
    }
}

.banner {
    position: relative;
}

.banner--illustration {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-color: $color-border;
    background-image: url(../images/banner-building.jpg);
}

.banner--content {
    position: absolute;
    width: 90%;
    bottom: 0;
    z-index: 2;

    @media #{$screen-xl-rule-min} {
        width: inherit;
    }
}

.banner--title {
    @include font-size($h2-fontsize-mobile);
    margin: 10px 0 40px;
    text-transform: uppercase;
    font-family: $heading-font-family;
    color: $color-white;
    text-shadow: 2px 2px 10px $color-black;

    @media #{$screen-m-rule-min} {
        @include font-size($h1-fontsize-mobile);
    }
    @media #{$screen-l-rule-min} {
        @include font-size($h1-font-size);
    }
}