ul.content--page-menu {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0;
    padding-left: 0;

    > li {    
        display: inline-flex;    

        .link {
            display: inline-flex;
            padding: 1rem;
            border-radius: .5rem;
            color: $color-black;
            text-decoration: none;
            background-color: $color-bg;

            &:focus,
            &:hover {
                background-color: $color-yellow;
            }

        }

        &.active {

            .link {
                color: $color-white;
                background-color: $color-blue;

                &:focus,
                &:hover {
                    background-color: $color-blue;
                }

            }  

        }
        
    }

}