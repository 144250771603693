.menu--anchor {
    display: none;
    position: fixed;
    left: 2%;
    top: 40%;
    z-index: 20;
    
    @include center(y);
    
    @media #{$screen-m-rule-min} {
        display: block;
    }
    
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    li {
        $width: 46px;
        
        width: $width;
        height: 15px;
        position: relative;
        text-align: center;
        margin: 0 0 12px;
        
        @include transition(all ease 0.35s);
        
        &:last-child {
            margin: 0;
        }
        &.active {         
            height: $width;
            
            a {        
                width: $width;
                height: $width;
                display: block;
            }
            
            .icon {
                width: $width;
                    height: $width;
                    line-height: $width;
                opacity: 1;
                
                @include font-size(22px);
                //@include transform(scale(1));
            }
        }
        &.in-action {         
            height: $width;
        }
        html.no-touch & {
            &:hover {        
                width: $width;
                height: $width;

                a {
                    width: $width;
                    height: $width;
                    display: block;
                }
            
                .icon {        
                    width: $width;
                    height: $width;
                    line-height: $width;
                    opacity: 1;
                    
                    @include font-size(22px);
                    //@include transform(scale(1));
                }
            }
        }
    }
    a {
        $width: 15px;
        
        width: $width;
        height: $width;
        position: absolute;
        top: 0;
        display: inline-block;
        background: $color-border;
        
        @include center(x);
        @include border-radius(100%);
        @include transition(all ease 0.35s);
        
        &.active {
            $width: 46px;
            
            width: $width;
            height: $width;
            display: block;
            
            .icon {
                width: $width;
                height: $width;
                line-height: $width;
                opacity: 1;
                
                @include font-size(22px);
                //@include transform(scale(1));
            }
        }
    }
    .icon {
        $width: 15px;
        
        display: block;
        width: $width;
        height: $width;
        line-height: $width;
        border: 2px solid $color-yellow;
        text-align: center;
        background: #fff;
        opacity: 0;
        
        @include transition(all ease 0.35s);
        @include font-size(2px);
        @include border-radius(46px);
        //@include transform(scale(0));
    }
}

.gotop {
    $width: 42px;
    
    display: block;
    cursor: pointer;
    text-align: center;
    width: $width;
    height: $width;
    line-height: $width;
    position: fixed;
    bottom: 5%;
    left: 2%;
    color: #3d3d3d;
    background: #fff;
    border: 2px solid #fff;
    z-index: 10;
    
    /* @include transition(all ease 0.3s);*/
    @include font-size(22px);
    @include border-radius($width);
        
    &:hover {
        border: 2px solid $color-yellow;
    }
    
    .icon {
        display: inline;
        border: none;
        color: $color-blue; 
        background: none;
    }
}

