fieldset {
    background-color: #fff;
    border: none;
    margin: 0 0 $small-spacing;
    padding: 0;
}

input,
label,
select {
    display: block;
    font-family: $base-font-family;
    color: $color-text;
    @include font-size($base-font-size);
}

label, 
.label {
    display: inline-block;
    margin-bottom: calc($small-spacing / 2);
    font-family: $serif-italic-font;
    font-style: italic;
    
    &.required::after {
        content: "*";
    }

    abbr {
        display: none;
    }
    
    .field--radio &, 
    .field--checkbox & {
        width: 90%;
        vertical-align: top;
        font-style: normal;
        font-weight: 500;
        font-family: $base-font-family;
    }
}
.label--blocnote {
    color: $color-white;
    font-style: normal;
    font-family: $base-font-family;
    @include font-size (13px);
}
#{$all-text-inputs},
select[multiple],
textarea {
    background-color: $base-color-background;
    border: $base-border;
    border-radius: $base-border-radius;
    box-shadow: $form-box-shadow;
    box-sizing: border-box;
    font-family: $base-font-family;
    @include font-size($base-font-size);
    margin-bottom: calc($base-spacing / 2);
    padding: calc($base-spacing / 3);
    @include transition(border-color, .2s ease);
    width: 100%;


    &:focus {
        border-color: $color-action;
        box-shadow: $form-box-shadow-focus;
        outline: none;
    }
}

textarea {
    resize: vertical;
}

input[type="search"] {
    @include appearance(none);
}

input[type="checkbox"],
input[type="radio"] {
    $width: 16px;
    
    width: $width;
    height: $width;
    position: relative;
    display: inline-block;
    margin-right: calc($small-spacing / 2);
    border: 1px solid $color-gray-light;
    
    -webkit-appearance: none;
    vertical-align: middle;
    background: #fff;
}

input[type="radio"] {
    @include border-radius(100%);
    
    &:checked {
        &:before {
            content: "";
            font-size: 1.8em;
            width: 10px;
            height: 10px;
            text-align: center;
            position: absolute;
            top: 21%;
            left: 21%;
            line-height: 0.55;
            background: $color-yellow;
            -webkit-border-radius: 10px;
            -khtml-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
            border-radius: 10px;
            left: 50%;
            top: 50%;
            bottom: auto;
            right: auto;
            
            @include center(xy);
        }
    }
}
input[type="checkbox"] {
    &:checked {
        &:before {
            @extend %icomoon;
            @extend .icon-checkmark;
            @include font-size(20px);
            color: $color-yellow;
            position: absolute;
            top: -4px;
            left: 0;
        }
    }
}

input[type="file"] {
    padding-bottom: $small-spacing;
    width: 100%;
}

select {
    margin-bottom: $base-spacing;
    width: 100%;
    height: 35px;
    font-weight: 500;
    border: 1px solid $color-gray-light;
    background: #fff;
    font-family: $base-font-family;
    
    @include font-size($base-font-size);
    @include border-radius(3px);
}

input[type="submit"] {
    color: $color-blue;
    border: 2px solid $color-blue;
    padding: 12px 18px;
    font-weight: 700;
    display: inline-block;
    margin-top: 20px;
    text-transform: uppercase;
    background: $color-white;
    
    @include font-size(14px);
    @include border-radius(6px);
    @include transition(all ease 0.5s);
    
    html.no-touch & {
        &:hover {
            color: #fff;
            background: $color-blue;
        }
    }
}
.mandatory {
    color: $color-red;
    padding-left: 6px;
}
legend {
    padding: 10px 0;
}

// Remove horrible outline on Android
// Src: http://stackoverflow.com/a/10070399
html.is-android {
    input,
    textarea,
    select,
    a {
        outline: none;

        &:focus {
            outline: none;
        }
    }
}

.field--item {
    margin: 0 0 10px;
    
    .field h4 {
        padding-left: 0;
    }
}
.field--label, 
.field {
    float: left;
}
.field--label {
    @media #{$screen-m-rule-min} {
        width: 30%;
        padding: 6px 20px 0 0;
        text-align: right;
        
        .field--item-radio &, 
        .field--item-checkbox & {
            padding-top: 0;
        }
    }
}
.field {
    width: 100%;
    
    @media #{$screen-m-rule-min} {
        width: 70%;
    }
    p, .powermail_file  {
        padding-top: 6px;
    }
}
#tx-solr-search h3 {
    padding-left: 0;
}