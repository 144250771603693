// ratio calculation

@function calculateRatio($value1, $value2, $units: null) {
    $integer1: strip-units($value1);
    $integer2: strip-units($value2);
    $ratio: $integer1 / $integer2;

    @if $units {
        @return $ratio + $units;
    }
    @else {
        @return $ratio;
    }
}
@function calculateRatioEm($value1, $value2) {
    @return calculateRatio($value1, $value2, em);
}

@function calculateRatioPx($value1, $value2) {
    @return calculateRatio($value1, $value2, px);
}



// return even/odd numbers

/*@function getEven($number, $ceil: null) {
    @if ($number % 2 != 0) {
        $number: $number - 1;
    }

    @if ($ceil == true) {
        @return ceil($number);
    } @else {
        @return floor($number);
    }
}

@function getOdd($number, $ceil: null) {
    @if ($number % 2 == 0) {
        $number: $number - 1;
    }

    @if ($ceil == true) {
        @return ceil($number);
    } @else {
        @return floor($number);
    }
}*/



// hd devices

@function retina($minmax: null, $breakpoint: null) {
    $min-density: 1.25;
    $rule-width: "min-width";
    $retina: "";

    @if $minmax {
        @if $minmax == max {
            $rule-width: "max-width";
        }

        $retina: "only screen and (-webkit-min-device-pixel-ratio: #{$min-density}) and (#{$rule-width}: #{$breakpoint})" +
            ", only screen and ( min--moz-device-pixel-ratio: #{$min-density}) and (#{$rule-width}: #{$breakpoint})" +
            ", only screen and ( -o-min-device-pixel-ratio: #{$min-density}) and (#{$rule-width}: #{$breakpoint})" +
            ", only screen and ( min-device-pixel-ratio: #{$min-density}) and (#{$rule-width}: #{$breakpoint})" +
            ", only screen and ( min-resolution: #{round($min-density*96)}dpi) and (#{$rule-width}: #{$breakpoint})" +
            ", only screen and (min-resolution: #{$min-density}dppx) and (#{$rule-width}: #{$breakpoint})";

    } @else {
        $retina: "only screen and (-webkit-min-device-pixel-ratio: #{$min-density})" +
            ", only screen and ( min--moz-device-pixel-ratio: #{$min-density})" +
            ", only screen and ( -o-min-device-pixel-ratio: #{$min-density})" +
            ", only screen and ( min-device-pixel-ratio: #{$min-density})" +
            ", only screen and ( min-resolution: #{round($min-density*96)}dpi)" +
            ", only screen and (min-resolution: #{$min-density}dppx)";
    }

    @return $retina;
}



// sprite shift depending on value and position

@function sprite-shift($value: 0, $position: 0, $otherPosition: 0, $vertical: false) {
    @if $vertical == true {
        //@return $otherPosition -($value * $position);
        @return $otherPosition (-($value * $position));
    } @else {
        //@return -($value * $position) $otherPosition;
        @return (-($value * $position)) $otherPosition;
    }
}
