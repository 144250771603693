.box--mag-content {
    position: relative;
    background-color: $color-gray-verylight;
    
    .mag--img, 
    .mag--text {
        float: left;
    }
    .mag--img {
        overflow: hidden;
        
        margin: 0 0 20px;
        position: relative;
        
        @media #{$screen-m-rule-min} {
            width: 38%;
            margin: 0 5% 0 0;
        }
    }
    .mag--text {
        @media #{$screen-m-rule-min} {
            width: 57%;
        }
    }
    .title {
        padding: 0;
        margin: 0 0 10px;
        font-weight: 900;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $color-text;        
        font-family: $base-font-family;
            
        @include font-size(17px);
    }
    .subtitle {
        font-style: italic;
        font-weight: 500;
        margin: 0 0 30px;
        
        @media #{$screen-l-rule-min} {
            @include font-size(13px);
        }
    }
    p {
        &:last-child {margin: 0;}
    }
}

