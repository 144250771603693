#cookies {
    padding: 12px 20% 12px 40px;
    text-align: center;
    color: $color-text;
    background: $color-gray-light;
    
    @media #{$screen-l-rule-min} {
        padding: 12px 4%;
    }
    
    p {
        margin: 0;
        @include font-size(13px);
    }
    a {
        color: $color-text;
        padding: 0 6px;
    }
    .close-cookies {
        position: absolute;
        right: inherit;
        left: 10px;
        top: 4%;
        width: 20px;
        height: 20px;
        opacity: 0.3;
        visibility: hidden;
    
        @media #{$screen-l-rule-min} {
            right: 32px;
            top: 10px;
            left: inherit;
        }
    }
    .close-cookies:hover {
      opacity: 1;
    }
    .close-cookies:before, .close-cookies:after {
      position: absolute;
      left: 15px;
      top: 0;
      content: ' ';
      height: 20px;
      width: 2px;
      background-color: $color-text;
      visibility: visible;
    }
    .close-cookies:before {
      transform: rotate(45deg);
    }
    .close-cookies:after {
      transform: rotate(-45deg);
    }
}