.ul--formation {
    width: 100%;
    padding-left: 0;
        
    @media #{$screen-l-rule-min} {
        padding: inherit;
    }
    
    & > li {
        list-style: none;
        display: inline-block;
        width: 100%;
        margin-bottom: 20px;
        vertical-align: top;
        padding-left: 0;
        
        @media #{$screen-l-rule-min} {
            width: 49%;
            padding-left: 40px;
        }
        
        &.li--double-width {
            width: 100%;
        }
        
        .link--icon .text {
            @include font-size(18px);
        }
    }
}
.ul--simplelist {
    padding: 0;
    margin: 0 0 30px 0;
    
    li {
        padding: 0;
        list-style: none;
        font-weight: 500;
        line-height: 130%;
    }
}