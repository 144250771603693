.sitemap-btn {
    padding: 40px 0;
    
    @include font-size(13px);
    
    @media #{$screen-m-rule-min} {
        border-top: 1px solid $color-blue;
    }
    
    ul {
        &.niv2 {
            padding: 20px 0 0 18px;
            
            @media #{$screen-m-rule-min} {
                padding: 40px 0 0 18px;
            }
        }
        &.niv3 {
            padding: 10px 0 0 10px;
            
            li {
                font-weight: normal;
            }
        }
    }
    li {
        margin: 0 0 10px;
        list-style: disc;
        
        &.niv1 {
            list-style: none;
            margin: 0 0 50px;
            font-weight: 900;
        }
        &.niv2 {
            font-weight: 900;
        }
        &.niv3 {
            font-weight: normal;
        }
    }
     .title {
        text-transform: uppercase;
        color: $color-yellow;
        font-weight: normal;
        
        @include font-size(16px);
    }
    li {
        a {
            position: relative;
            
            &:after {
                content: "";
                position: absolute;
                left: 0;
                width: 100%;
                height: 1px;
                background: $color-white;
                opacity: 0;

                @include transition(all 0.45s ease);
            }
            &:after {
                bottom: -5px;
            }
            &:hover {
                &:after {
                    opacity: 1;
                    bottom: -2px;
                }
            }
            &.title {
                &:after {
                    background-color: $color-yellow;
                } 
            }
        }
    }
}
.footer--sitemap {
    display: none;
    
    @media #{$screen-l-rule-min} {
        display: block;
    }
    
    > ul {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        column-count: 3;
        
        &.no-csscolumns {
            li {
                display: inline-block;
                vertical-align: top;
            }
            li li {
                display: block;
            }
        }
        
        &.grid {
            li.niv1 {
                width: 30%;
            }
        }
    }
    li {
        &.niv1 {
            padding-right: 30px;
        }
    }
}