$main-title-color: #b0afab;
$main-text-color: #2a2a2a;
$main-color: #c3371e;
$color-icon-menu: #b0b2b3;

#calendrier {
    position: relative;
    width: 100%;
    border: 1px solid $main-title-color;
    box-sizing: border-box;
    
    .loader-ajax {
        width: auto;
        height: auto;
        
        position: absolute;
        
        @include center(xy);
    }
    table {
        border: none;
    }
    tr {
        background: none;
        
        &:first-of-type td {
            padding-top: 16px;
        }
    }
    th, 
    td {
        color: $color-text;
        padding: 0;
        background: none;
    }
    th {
        font-size: 0.75em;
        text-transform: uppercase;
        font-weight: 600;
        padding: 0 0 20px;
    }
    td {
        text-align: center;
        vertical-align: middle;
        padding-bottom: 8px;
        border: none;
        position: inherit;
        padding-bottom: 16px;
        
        @media #{$screen-m-rule-min} {
            position: relative;
        }
        
        a,
        span.link-day{
            display: inline-block;
            color: $color-text;
            width:29px;
            height: 29px;
            top: 50%;
            margin: 1px;
            line-height:28px;
            box-sizing: border-box;
            
            @include center(y);
            @include border-radius(30px);
            @include font-size(16px);
            
            @media #{$screen-l-rule-min} {
                width: 25px;
                height: 25px;
                line-height: 24px;
                margin: 1px 3px 4px;
            }
            
        }
        &.active {
            a {
                background: $color-yellow;
            }
        }
        &.disabled {
            a, 
            span.link-day{
                border: none;
                color: $main-title-color;
            }
            &.active .link-day {                
                border: 2px solid $color-yellow;
                background: #fff;
            }
            &:hover a {
                color: $main-title-color;
            }
        }
        &.today, 
        &:hover{
            a {
                color: #fff;
                border-color: $color-blue;
                background: $color-blue;
            }
            
            .calendrier-popin {
                a {
                    color: $main-text-color;
                }
            }
        }
        li {
            a {
                &.title, 
                &.btn{
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
    a {
        text-decoration: none;
        @include transition(all ease 0.35s);
    }
    .hidden {
        display: none;
    }
    .calendrier-info-date {
        font-size: 1.2em;
        color: $color-yellow;
        border-bottom: 2px solid $color-blue-light;        
        padding: 0 0 15px;
        margin: 0 0 20px;
        
        span {
            font-size: 1.2em;
            font-weight: 900;
        }
    }    
    .thematique {
        color: #fff;
        display: inline-block;
        margin: 0 0 5px;
        text-transform: uppercase;
    }
    .icon-angle-right {
        color: $color-yellow;
    }
    .calendar-close { 
        &:before, 
        &:after {
            content:"";
            position: absolute;
            width: 21px;
            height: 2px;
            top: 14px;
            background-color: $color-border;
            
            @include transition(all, ease, .4s);
        }
        &:before {
            left: 5px;
            
            @include transform(rotate(45deg));
        }
        &:after {
            right: 5px;
            
            @include transform(rotate(-45deg));
        }
        span {
            display: none;
        }
    }
}
.calendrier-title {
    text-align: center;
    padding: 15px 0;
    font-style: italic;
}
.calendrier-header {
    position: relative;
    border-bottom: 1px solid $main-title-color;
    margin: 0 20px 15px;
    
    @media #{$screen-l-rule-min} {
        margin: 0 10px 15px;
    }
    
    a {
        position: absolute;
        top: 0;
        padding: 12px 0 0;
        
        @include transition(all ease 0.35s);
        
        &.calendrier-prev {
            left: 0;
        }
        &.calendrier-next {
            right: 0;
        }
        span {
            display: none;
        }
        &:hover {
            .icon-prev:before, .icon-next:before {
                color: $main-color;
            }
        }
    }
    .icon-prev, 
    .icon-next {
        font-size: 1.2em;
        
        &:before {
            margin: 0;
            color: $color-icon-menu;
        }
        
    }
}
.calendrier-popin {
    display: none;
    position: absolute;
    border: none;
    text-align: left;
    overflow: inherit;
    background: $color-blue;
    box-shadow: 1px 1px 12px #555;
    z-index: 90;    
    width: 92%;
    padding: 20px 4%;
    margin: 4%;
    right: 0;
    top: 0;
    bottom: inherit;
    
    @media #{$screen-m-rule-min} {
        width: 300px;
        padding: 20px;
        right: -8px;
        top: inherit;
        bottom: 69px;
        margin: 0; 
    }    
    
    &:after {
        content: "";
        position: absolute;
        right: 20px;
        bottom: -13px;
        display: none;
        border-left: 13px solid transparent;
        border-top: 13px solid $color-blue;
        border-right: 13px solid transparent;
        
        @media #{$screen-m-rule-min} {
            display: block;
        }
    }
    ul {
        padding: 0;
        margin: 0;
    }
    li {
        list-style: none;
        margin: 20px 0 0;
        border-bottom: 2px solid $color-blue-light; 
        
        + li {
            padding: 10px 0 0;
            margin: 10px 0 0;
            border-top: 2px solid #ccc;
        }
        &:last-of-type {
            border: none;
        }
    }
    .date {
        font-size: 0.8em;
        display: inline-block;
        text-align: left;
        font-style: normal;
        color: $color-yellow;
    }
    a {
        display: block;
        color: #fff;
        
        &:hover {
            color: $color-yellow;
        }
        
        #calendrier td & {
            display: block;
            width: auto;
            height: auto;
            border: none;
            padding:0;
            line-height: 1;
            background: none;
            border-radius: 0;     
            top: inherit;
            transform: none;
            
            &.link-more {
                float: right;
                text-decoration: none;
                display: inline-block;
                margin-top: -10px;

                @include transition(all ease .5s);
                
                .icon-plus {
                    color: $color-yellow;
                    font-style: normal;
                    
                    @include font-size (38px);
                    @include transition(all ease .5s);
                
                    &:hover {
                        color: #fff;

                        @include transition(all ease .5s);
                    }
                }
            }
            &.calendar-close {
                display: block;
                position: absolute;
                width: 31px;
                height: 31px;
                right: 10px;
                top: 10px;
                
                @media #{$screen-m-rule-min} {
                    display: none;
                }
            }
            &:hover {
                color: $main-text-color;
            }
            &.btn {
                font-size: 0.8em;
                margin: 10px 0;
                
                span,
                i {
                    display: inline-block;
                    vertical-align: middle;
                }
                i:before {
                    margin: 0 0 0 4px;
                    font-size: 1.5em
                }
            }
        }
    }
    .article--tags {
        padding: 0 0 20px 0;
        margin: 0;
        display: inline-block;
        
        li {
            padding: 0;
            margin: 0;
            border: none;
        }
    }
}

#calendrier td .calendrier-popin a,
#calendrier:hover td .calendrier-popin a {
    color: #fff;
}