$margin: 4%;
$width-1of3: calculateBlockWidth($margin, 3);
$width-2of3: (2 * calculateBlockWidth($margin, 3)) + $margin;

#col--main, 
#col--right {
    @media #{$screen-m-rule-min} {
        float: left;
    }
}
#col--main {
    @media #{$screen-m-rule-min} {
        width: $width-2of3;
        margin: 0 $margin 0 0;
    } 
}
#col--right {
    @media #{$screen-m-rule-min} {
        width: $width-1of3;
    } 
}