nav {
    ul {
        margin: 0;
        padding: 0;
    }
    a {
        text-decoration: none;
    }
}

.nav-main {
    $blockContext: &;

    display: none;
    width: 100%;
    z-index: $zindex-nav;

    @media #{$screen-header-rule-min} {
        flex: 1;
        display: flex;
        justify-content: center;
        width: auto;
    }

    &--list {
        display: flex;
        align-items: center;
        gap: 2px;
    }

    &--item {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    &--link {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 18px;
        height: 60px;
        position: relative;
        padding: 0 18px 0 0;
        border: 0;
        border-radius: 9px;
        font-weight: 700;
        color: $color-text;
        background-color: transparent;

        &::before {
            content: "";
            display: block;
            width: 1px;
            height: 40px;
            background-color: $color-border;
        }

        html.no-touch &:focus,
        html.no-touch &:hover,
        &.active {
            color: $color-white;
            background-color: $color-blue;

            &::before {
                background-color: transparent;
            }
        }
    }

    &--link-label {
        @include font-size(12px);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        text-transform: uppercase;

        &::after {
            @include font-size(6px);
            content: "\25BC";
            color: $color-blue;
        }

        html.no-touch #{$blockContext}--link:focus &,
        html.no-touch #{$blockContext}--link:hover &,
        #{$blockContext}--link.active & {
            &::after {
                color: $color-white;
            }
        }

        #{$blockContext}--link.active & {
            &::after {
                transform: rotate(180deg);
            }
        }
    }


    &--megamenu {
        display: none;
        width: 230px;
        position: absolute;
        top: calc(100% + 2px);
        left: 0;
        z-index: 5;
        padding: 20px;
        border-radius: 9px;
        background-color: $color-blue;
    }
}

.megamenu {
    &--list {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &--item-title,
    &--item {
        display: block;
    }

    &--item-title {
        padding-bottom: 10px;
        border-bottom: 1px solid $color-white;
    }

    &--link-title,
    &--link {
        font-weight: 700;
        color: $color-white;

        html.no-touch &:focus,
        html.no-touch &:hover {
            color: $color-yellow;
            text-decoration: underline;
        }
    }

    &--link {
        @include font-size(12px);
    }

    &--link-title {
        @include font-size(14px);
        text-transform: uppercase;
    }
}

#nav, #header .layout--maxwidth {
    position: static;
}