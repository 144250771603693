.block--documentation {
    display: grid;

    @media #{$screen-l-rule-min} {
        //grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
    }

    &.clearfix::before,
    &.clearfix::after,
    > .clearfix {
        display: none;
    }

    .block-1of2 {
        display: flex;

        @media #{$screen-m-rule-min} {
            float: unset;
        }

        @media #{$screen-l-rule-min} {
            width: unset;
        }
    }
}
