.block--event {
    $margin: 2.5%;
    $width-1of3: calculateBlockWidth($margin, 3);

    position: relative;
    
    .layout--maxwidth {
        padding: 0 0 60px;
        
        @media #{$screen-m-rule-min} {
            padding: 90px 3%;
        }
        @media #{$screen-l-rule-min} {
            padding: 90px 5%;
        }
        @media #{$screen-xl-rule-min} {
            padding: 90px 0;
        }
    }
    .ratio {
        padding-top: 110%;
    }
    .block-1of3 {
        margin: 0 0 20px;
        
        &:last-child {
            margin: 0;
        }
        @media #{$screen-m-rule-min} {
            width: $width-1of3;
            margin: 0 $margin 0 0;
            
            &:last-child {
                margin: 0;
            }
        }
    }
    .description {
        line-height: 70px;
    }
    .title {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        line-height: 1;
        font-weight: 700; 
    }
    .article--date {
        width: 44px;
        line-height: 70px;
        display: table-cell;
        vertical-align: middle;
        
        .date {
            display: inline-block;
            vertical-align: middle;
        }
        p {
            line-height: 1;
            
            @include font-size(21.5px);
        }
    }
    .day {
        margin-bottom: 2px;
    }
    .article--date-startend {
        width: auto;
    }
/*    .date--startend {
        .description {
            @media #{$screen-m-rule-min} {
                padding: 10px 40% 10px 20px;
            }
            @media #{$screen-l-rule-min} {
                padding: 10px 30% 10px 20px;
            }
        }
    }*/
    .date--start {
        padding: 0 15px 0 0;
        margin: 0 3px 0 0;
        background: url("../images/date-arrow.png") no-repeat right center;
    }
    .link--type {
        margin-top: 0;
        
        @media #{$screen-m-rule-min} {
            margin-top: 15px;
        }
    }
}
