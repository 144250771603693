.documentation {
    $blockContext: &;

    display: flex;

    &--link {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 100%;
        text-decoration: none;
    }

    &--caption {
        @include font-size(18px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        padding-left: 16px;
        border-left: 4px solid $color-yellow;
        color: $color-blue;

        @media #{$screen-l-rule-min} {
            @include font-size(22px);
        }

        #{$blockContext}-dark & {
            color: $color-white;
        }
    }

    &--title {
        margin: 0;
        padding-left: 0;

        #{$blockContext}-dark & {
            color: $color-white;
        }
    }

    &--summary {

        p {
            margin-bottom: 0;
        }
    }
}