.box--list {
    .ul--rte {
        margin: 0;
    }
    li {
        color: $color-gray;
        margin: 0;
    }
    p {
        margin: 0;
    }
}
.w-80 {
    display: block;
    width: 80%;
}
.box--docs-list {
    padding: 0;
    margin: 20px 0 10px;
    list-style: none;
    
    li {
        margin: 0 0 30px;
        
        &:last-child {
            margin: 0;
        }
    }
    .title {
        margin: 0;
        padding: 0;
        font-weight: 900;
        
        @include font-size(17px);
    }
    .file {
        margin: 0 0 10px;
        font-style: italic;
        font-weight: 500;
        color: $color-text;
        
        @include font-size(13px);
    }
}

