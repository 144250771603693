.btn,
.btn--border,
.btn--border-color {
    @include font-size(14px);
    @include border-radius(6px);
    display: inline-block;
    padding: 8px 18px;
    border: 0;
    font-weight: 700;
    color: $color-white;
    text-decoration: none;
    background-color: $color-blue;

    html.no-touch & {
        &:focus,
        &:hover {
            .text {
                color: $color-yellow;
            }
        }
    }
}

.btn-alt {
    color: $color-blue;
    background-color: $color-white;

    html.no-touch & {
        &:focus,
        &:hover {
            background-color: $color-yellow;

            .text {
                color: $color-blue;
            }
        }
    }
}


// .btn--border {
//     border: 2px solid $color-blue;
//     color: $color-blue;
//     background-color: $color-white;
// }

// .btn--border-color {
//     border-color: $color-yellow;
// }

.link--plus {
    color: $color-blue;
    text-decoration: none;
    
    i {        
        font-style: normal;
    }
    
    html.no-touch &:hover i {
        color: $color-yellow;
    }
}

.link--type {
    display: inline-flex;
	align-items: center;
	gap: 4px;
    margin-top: 15px;
    text-decoration: none;
    color: $color-blue;
    font-weight: bold;

    &::before {
        @extend %icomoon;
        @extend .icon-angle-right;
        @include font-size(20px);
    }

    html.no-touch & {
        &:focus,
        &:hover {
            .text {
                text-decoration: underline;
            }
        }
    }

    @media #{$screen-m-rule-min} {
        padding-right: 0;
        margin-right: 0;
    }

    .text {
        display: inline-block;
        vertical-align: middle;

        #col--right & {
            @include font-size(12px);
        }
    }

    .icon-plus {
        display: none;
    }
}

.link--icon {
    display: flex;
    margin: 0 10px 0 0;
    text-decoration: none;

    .icon,
    .text {
        display: inline-block;
        vertical-align: bottom;
    }

    .text {
        @include font-size(14px);
        position: relative;
        font-weight: 700;

        &:after {
            @include transition(all ease 0.5s);
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: $color-blue;
        }
    }
    .icon {
        @include font-size(20px);
        margin: 0 4px 0 0;

        &:before {
            display: block;
        }

    }
    html.no-touch & {
        &:hover {
            .text {
                &:after {
                    bottom: -7px;
                    opacity: 0;
                }
            }
        }
    }

    .bg--blue & {
        color: $color-white;
    }
}

.icon-internal-link,
.icon-internal-link-new-window,
.icon-external-link,
.icon-external-link-new-window,
.icon-download {
    &:before {
        @extend .icon-link;
        @include font-size(13px);
        display: inline-block;
        margin-right: 8px;
    }
}

.icon-download:before {
    @extend .icon-download;
    @include font-size(16px);
    vertical-align: top;
}

#block--link-animations {
    text-align: center;
    padding: 15px 0;
    background-color: $color-blue-dark;
}

[id^="link--dechet-"] {
    display: inline-block;
    width: 130px;
    height: 135px;
    margin: 10px auto;
    background-repeat: no-repeat;
    background-size: auto 135px;
    background-position: center center;
    transition: initial;

    strong {
        display: none;
    }
}

#link--dechet-verre {
    background-image: url(/fileadmin/user_upload/menu_01.svg);

    &:hover {
        background-image: url(/fileadmin/user_upload/menu_01_over.svg)!important;
    }
}

#link--dechet-metal {
    background-image: url(/fileadmin/user_upload/menu_02.svg);

    &:hover {
        background-image: url(/fileadmin/user_upload/menu_02_over.svg)!important;
    }
}

#link--dechet-carton {
    background-image: url(/fileadmin/user_upload/menu_03.svg);

    &:hover {
        background-image: url(/fileadmin/user_upload/menu_03_over.svg)!important;
    }
}

#link--dechet-plastique {
    background-image: url(/fileadmin/user_upload/menu_04.svg);

    &:hover {
        background-image: url(/fileadmin/user_upload/menu_04_over.svg)!important;
    }
}

#link--dechet-matiere {
    background-image: url(/fileadmin/user_upload/menu_05.svg);

    &:hover {
        background-image: url(/fileadmin/user_upload/menu_05_over.svg)!important;
    }
}

#link--dechet-bio {
    width: 156px;
    background-image: url(/fileadmin/user_upload/menu-1.svg);

    &:hover {
        background-image: url(/fileadmin/user_upload/menu-1-over.svg)!important;
    }
}

#link--dechet-ordure {
    width: 156px;
    background-image: url(/fileadmin/user_upload/menu-2.svg);

    &:hover {
        background-image: url(/fileadmin/user_upload/menu-2-over.svg)!important;
    }
}
