header {
    a {
        text-decoration: none;
    }
}

#header {
    display: flex;
    align-items: center;
    height: 70px;
    position: relative;
    z-index: $zindex-header;
    border-bottom: 1px solid $color-gray;

    @media #{$screen-header-rule-min} {
        height: 80px;
    }
}

.preheader {
    display: none;
    position: relative;
    padding: 15px 0 0;
    background: $color-white;

    @media #{$screen-header-rule-min} {
        display: block;
    }

    .text {
        display: block;
        height: 0;
        text-indent: -5000px;
    }
}

.preheader--content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
    width: 100%;

    .btn {
        @include border-radius(9px);
        @include font-size(14px);
        vertical-align: middle;
        padding: 8px 18px;
        background: $color-blue;

        .text {
            color: $color-white;
            font-weight: bold;
            text-transform: none;
            display: inline !important;
        }
    }
}

.preheader--socials {
    display: flex;
    gap: 10px;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.preheader--socials-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    color: $color-white;
    background-color: $color-blue;

    .icon {
        @include font-size(20px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:focus,
    &:hover {
        color: $color-yellow;
    }
}

.header--main {
    display: flex;
    align-items: center;
    gap: 10px;

    @media #{$screen-header-rule-min} {
        justify-content: space-between;
        max-width: 1200px;
        margin-right: auto;
        margin-left: auto;
    }
}
