ul,
ol {
    //list-style-type: none;
    // margin: 0;
    // padding: 0;

    // TODO: are those rules needed?
    clear: both;
    margin: 30px 0;
}

dl {
    //margin-bottom: $small-spacing;

    dt {
        font-weight: bold;
        //margin-top: $small-spacing;
    }

    dd {
        margin: 0;
    }
}
