.tools {
    display: flex;
	justify-content: flex-end;
    padding: 20px 9px 0;
    background-color: $color-white;

    &--list {
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 0;
        padding: 0 0 4px 0;
        list-style: none;
	    border-bottom: 2px solid $color-gray-light;
    }

    &--item {
        &.no--mobile {
            display: none;

            @media #{$screen-m-rule-min} {
                display: block;
            }
        }
    }

    &--link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        padding: 0;
        border: 0;
        color: $color-blue;
        text-decoration: none;
        background-color: transparent;
    }

    &--icon {
        @include font-size(22px);
        display: flex;
    }
}