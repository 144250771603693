.block {
    $margin: 0;
    $width-1of3: calculateBlockWidth($margin, 3);
    $width-2of3: (2 * calculateBlockWidth($margin, 3)) + $margin;
    $width-1of5: calculateBlockWidth($margin, 5);
    $width-1of4: calculateBlockWidth($margin, 4);
    $width-3of4: (3 * calculateBlockWidth($margin, 4)) + 2 * $margin;
    $width-1of5: calculateBlockWidth($margin, 5);
    $width-2of5: (2 * calculateBlockWidth($margin, 5)) + $margin;
    $width-4of5: (4 * calculateBlockWidth($margin, 5)) + 3 * $margin;
    $width-1of6: calculateBlockWidth($margin, 6);
    $width-5of6: (5 * calculateBlockWidth($margin, 6)) + 4 * $margin;
 
    
    &#{"-1of2"},
    &#{"-1of3"},
    &#{"-2of3"},
    &#{"-1of4"}, 
    &#{"-3of4"},
    &#{"-1of5"}, 
    &#{"-2of5"},
    &#{"-4of5"},
    &#{"-1of6"},
    &#{"-5of6"}{
        @media #{$screen-m-rule-min} {
            float:left;
            margin:0 $margin 0 0;
        }
        
        &.last, 
        &:last-child {
            margin-right: 0;
        }
    }
    &#{"-1of2"} {
        @media #{$screen-l-rule-min} {
            width: calculateBlockWidth($margin, 2);
        }
    }
    &#{"-1of3"} {
        @media #{$screen-m-rule-min} {
            width: $width-1of3;
        } 
    }
    &#{"-1of4"} {
        @media #{$screen-m-rule-min} {
            width: $width-1of4;
        } 
    }
    &#{"-2of3"} {
        @media #{$screen-m-rule-min} {
            width: $width-2of3;
        } 
    }
    &#{"-3of4"} {
        @media #{$screen-m-rule-min} {
            width: $width-3of4;
        } 
    }
    &#{"-1of5"} {
        @media #{$screen-l-rule-min} {
            width: $width-1of5;
        } 
    }
    &#{"-2of5"} {
        @media #{$screen-l-rule-min} {
            width: $width-2of5;
        } 
    }
    &#{"-4of5"} {
        @media #{$screen-l-rule-min} {
            width: $width-4of5;
        } 
    }
    &#{"-1of6"} {
        @media #{$screen-l-rule-min} {
            width: $width-1of6;
        } 
    }
    &#{"-5of6"} {
        @media #{$screen-l-rule-min} {
            width: $width-5of6;
        } 
    }
}
.block--full {
    padding: 30px 0;
    
    @media #{$screen-xl-rule-min} {
        padding: 50px 0;
    }
    .full--width {
        margin: 0;
    }
}



//GRID
.block--grid {
    @include outer-container;
    
    div {
        &:last-child {
            margin-right: 0;
        }
    }
}
.block--1of12 {
    @media #{$screen-l-rule-min} {
        @include span-columns(1);
    }
}
.block--2of12 {
    @media #{$screen-m-rule-min} {
        @include span-columns(4);
        
        &.col--left {
            width: 100%;
            margin-right: 0;
        }
    }
    @media #{$screen-l-rule-min} {
        @include span-columns(2);
        
        &.col--left {
            @include span-columns(2);
        }
    }
}
.block--3of12 {
    @media #{$screen-l-rule-min} {
        @include span-columns(3);
    }
}
.block--4of12 {
    @media #{$screen-l-rule-min} {
        @include span-columns(4);
    }
}
.block--5of12 {
    @media #{$screen-l-rule-min} {
        @include span-columns(5);
    }
}
.block--6of12 {
    @media #{$screen-l-rule-min} {
        @include span-columns(6);
    }
}
.block--7of12 {
    @media #{$screen-l-rule-min} {
        @include span-columns(7);
    }
}
.block--8of12 {
    @media #{$screen-l-rule-min} {
        @include span-columns(8);
    }
}

.block--9of12 {
    @media #{$screen-l-rule-min} {
        @include span-columns(9);
    }
}
.block--10of12 {
    @media #{$screen-l-rule-min} {
        @include span-columns(10);
    }
}
.block--11of12 {
    @media #{$screen-l-rule-min} {
        @include span-columns(11);
    }
}
.block--last {
    @include omega();
}
.block--2cols {
    $margin: 3%;
    $width-1of2: calculateBlockWidth($margin, 2);
    
    .block-1of2 {
        width: 100%;
        
        @media #{$screen-s-rule-min} {
            float: left;
            width: $width-1of2;
            margin-right: $margin;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    .triangle {
        &:after {
            bottom: -10px;
            
            .one--col & {
                bottom: -4px;
            }
        }
    }
}


.block-1of2 {
    .block--number-content {
        margin-right: 20px;
    }
}
.block--flash {
    background: #fff;
    border-radius: 5px;
    padding: 20px; 
    margin-bottom: 40px;
    color: $color-blue;
    
    p {
        display: inline-block;
        font-weight: 600;
    }
    .icon {       
        vertical-align: text-bottom;
        margin-right: 20px;
    }
}







.favorites--menu-ul {
    margin: 0;
    position: absolute;
    top: 32px;
    right: 0;
    
    @media #{$screen-s-rule-min} {
        top: 16px;
    }
    
    li {
        list-style: none;
        display: inline-block;
        padding: 0 20px 0 0;
        
        &:last-of-type {
            padding: 0;
        }
        a {
            text-decoration: none;
            
            &:hover .icon {
                color: $color-yellow;
                @include transition(all ease 0.5s);
            }
        }
        &:after {
            content: "";
            width: 20px;
            height: 30px;
            border-right: 1px solid $color-gray;
            display: inline-block;
        }
        &:last-of-type:after {
            border-right: 0;
        }
        
        .icon-medium {
            @include font-size(30px);
            
            @media #{$screen-s-rule-min} {
                @include font-size(40px);
            }
        }
    }
}

.favorites--menu-input {
    color: $color-gray; 
    width: 25%;
    
    @media #{$screen-s-rule-min} {
        width: 42%;
    }
    
    label {
        @include font-size(13px);
        
        @media #{$screen-s-rule-min} {
            @include font-size(15px);
        }
    }
}

.tab--container .tab--content .block--imgslider {
    margin: 0 20px;
    
    .block-1of2 {
        width: 100%;
        margin-bottom: 20px;

        @media #{$screen-l-rule-min} {
            width: 50%;
        }

        &:first-child {
            text-align: center;

            @media #{$screen-l-rule-min} {
                text-align: initial;
            }  

            a img {
                width: auto;

                @media #{$screen-s-rule-min} {
                    height: 170px;
                } 
                @media #{$screen-xl-rule-min} {
                    height: 217px;
                }  
            }
            figure {
                height: 200px;
                width: 100%;
                margin: 0;
                
                iframe {
                    height: 100%;
                    width: 100%;
                    border: none;
                }
                .figcaption--main {
                    position: absolute !important;
                }
                
                @media #{$screen-s-rule-min} {
                    height: 300px;
                }
                @media #{$screen-m-rule-min} {
                    height: 275px;
                }
                @media #{$screen-l-rule-min} {
                    height: 170px;
                    width: auto;
                }
                @media #{$screen-xl-rule-min} {
                    height: 217px;
                }
            } 
        }
    }
}
