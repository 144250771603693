#nav-footer {
    padding: 20px 0;
    color: $color-white;
    text-align: center;

    @media #{$screen-m-rule-min} {
        padding: 0 0 40px 0;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 12px;
        margin: 0;
        padding: 0;

        @media #{$screen-m-rule-min} {
            gap: 40px;
        }
    }

    li {
        list-style: none;
    }

    a {
        @include transition(all ease 0.5s);
        @include font-size(12px);
        display: block;
        position: relative;
        padding: 5px 0;
        font-weight: normal;
        font-family: $base-font-family;
        color: $color-white;
        text-decoration: none;

        @media #{$screen-m-rule-min} {
            @include font-size(14px);
        }

        &:after {
            @include transition(all 0.45s ease);
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            background-color: $color-white;
            opacity: 0;
        }

        &:after {
            bottom: -7px;
        }

        &:hover {
            &:after {
                bottom: 0;
                opacity: 1;
            }
        }
    }
}