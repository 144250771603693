.article--skin {
    cursor: pointer;
    overflow: hidden;
    position: relative;
    
    .box & {
        margin: 0 10px 10px;
    }
    .ratio {
        width: 100%;
        padding-top: 90%;
        background: $color-blue;
    }
    img {
        width: 100%;
        height: 100%;
        max-width: inherit;
        object-fit: cover;
        object-position: center;
        
        /*@media #{$screen-l-rule-min} {
            width: auto;
            height: 100%;
            max-width: inherit;
        }*/
        
        &:before, 
        &:after {
            content: "";
            display: block;
        }
        
    }
    figure {
        margin: 0;
        width: 100%;
        height: 100%;
    }
    .article--content {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }
    a {
        color: $color-white;
        text-decoration: none;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .block--share-icons {
        padding: 5px 0;
    }
    figcaption {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: $color-yellow;
    }
    .icon-share {
        @include font-size(35px);
        
        &:before {
            display: block;
        }
    }
    .description {
        width: 100%;
        padding: 14px;
        background-color: $color-blue-dark;
        
        @include font-size(12px);
        
        @media #{$screen-l-rule-min} {
            @include font-size(15px);
        }
    }
    .description li {
        display: inline-block;
    }
    .description p.date {
        color: $color-white;
        padding: 0;
        margin: 0 0 10px;
        
        @include font-size(11px);
        
        @media #{$screen-l-rule-min} {
            @include font-size(13px);
        }
    }
    .title {
        @include font-size(12px);
        margin: 0 0 10px;
        padding: 0;
        font-weight: 700;
        color: $color-white;
        text-transform: none;

        @media #{$screen-m-rule-min} {
            @include font-size(15px);
            margin: 0 0 15px;
        }
    }
    &.article--skin-big {
        @media #{$screen-l-rule-min} {
            .title {
                font-weight: 900;

                @include font-size(25px)
            }
            .description {
                padding: 20px;
            }
        }
    }
    html.no-touch & {
        &:hover {
            .filter--hover {
                opacity: 1;
                
                .icon-plus {
                    top: 32%;
                    left: 46%;
                    @include transform(scale(1));
                }
            }
        }
    }
}
.bg--img-page-temoignage, .bg--img-page-verbatim {
    
    .filter--hover .icon-plus {
        margin-top: -25px;
        margin-left: -25px;
        top: 50%;
        left: 50%;
    }            
    &:hover {
    
        .filter--hover {
            opacity: 1;

            .icon-plus {
                @include transform(scale(1));
            }
        }
    }
}
.article--content {
    width: 100%;
}

.article--tags {
    li {
        color: #fff;
        margin: 0 10px 0 0;
        font-style: italic;
        font-weight: 500;
        display: inline-block;
        
        @include font-size(12px);
        
        .article--skin-big & {
            @media #{$screen-l-rule-min} {
                @include font-size(16px);
            }
        }
    }
}
.article--date {
    padding: 10px;
    color: $color-blue;
    background: $color-yellow;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    
    @include font-size(20px);
    
    @media #{$screen-l-rule-min} {
        @include font-size(25px);
    }
    
    .article--skin-big & {
        text-align: center;
        
        @media #{$screen-l-rule-min} {
            @include font-size(45px);
        }
    }
    p {
        margin: 0;
        
        @include font-size(20px);
    
        @media #{$screen-l-rule-min} {
            @include font-size(25px);
            
            .article--skin-big & {
                @include font-size(45px);
            }
        }
    }
    .day {
        position: relative;
        font-weight: 500;
        
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: $color-blue;
        }
    }
    .month {
        font-weight: 100;
    }
}
.filter--hover {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    color: $color-yellow;
    width: 100%;
    height: 100%;
    background: rgba($color-blue, 0.7);
    
    @include transition(all ease 0.3s);
    
    .icon-plus {
        position: absolute;
        top: 32%;
        left: 46%;
        width: 50px;
        height: 50px;
        
        @include transform(scale(0));
        @include transition(all ease 0.5s);
        
        .article--skin-big & {
            @media #{$screen-l-rule-min} {
                top: 37%;
                left: 48%;
            }
        }
        &:before, 
        &:after {
            content: "";
            display: block;
            width: 50px;
            height: 2px;
            background: $color-yellow;
            position: absolute;
            top: 45%;
            
            @include center(y);
            
        }
        &:after {  
            @include transform(rotate(90deg));
        }
    }
}