#mobile-nav {
    $color-nav-primary: $color-gray;
    $color-nav-secondary: $color-white;
    $transition-duration: .3s;
    $no-css-class: no-csstransforms;

    
    @media #{$screen-header-rule-min} {
        display: none;
    }
    
    .menu-icon {
        z-index: $zindex-nav + 5;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 5px;
        width: $screen-s-header-height;
        height: $screen-s-header-height;

        @include transition(all $transition-duration ease);

        &.active {
            @include transform(rotate(-90deg));
        }
    }
    
    li {
        border-bottom: 1px solid rgba(#fff, 0.6);
        padding: 17px 0;
        list-style: none;
    }
    a {
        font-weight: bold;
        color: $color-white;
        
        -webkit-font-smoothing: antialiased;
    }
    
    .nav {
        visibility: hidden;
        width: 100%;
        padding: 15px;
        
        position: absolute;
        top: 70px;
        bottom: 0;
        left: -320px;
        overflow-x: hidden;
        overflow-y: scroll;
        background: $color-blue;
        
        z-index: 30;
        
        @include transition(all 0.4s cubic-bezier(0.075,0.82,0.165,1));
        
        &.active {
            visibility: visible;
        }
        .csstransforms3d & {
            left: 0;
            
            @include transform(translate3d(-100%,0,0));
            
            &.active {
                @include transform(translate3d(0,0,0));
            }
        }
    }
    .submenu {
        padding: 0 0 0 20px;
    }
    
    // burger icon
    span {
        $width: $screen-s-header-height - 28;
        $height: 3px;

        position: absolute;
        display: block;
        top: 50%;
        left: 0;
        width: $width;
        height: $height;

        margin-top: -(calc($height / 2));
        margin-left: calc(($screen-s-header-height - $width) / 2);

        background: $color-blue;
        font-size: 0px;

        @include user-select(none);
        @include text-size-adjust(none);
        @include webkit-no-touch-callout;
        @include webkit-no-tap-highlight;

        @include transition(background $transition-duration ease);

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $color-blue;

            @include transition(all $transition-duration ease);

            html.no-csstransforms & {
                content: none;
            }
        }
        &:before {
            @include transform(translateY(-300%));
        }
        &:after {
            @include transform(translateY(300%));
        }
    }
    // fallback
    html.#{$no-css-class} & {

        &:before {
            content: '\e800';
            font-family: 'icons';

            position: absolute;
            top: 4px; // icon is off-center
            left: 0;
            width: 100%;
            height: 100%;

            background: none !important;

            line-height: $screen-s-header-height;

            @include font-size(28px, false);
            color: $color-nav-primary;
            text-align : center;

            @include icon-reset(false);

            @include transition(color $hover-transition-duration ease);
        }

        span {
            display: none;
        }
    }


    &.active {

        span {
            background: transparent !important;

            &:before,
                &:after {
                background: $color-nav-secondary;
            }
            &:before {
                @include transform(translateY(0) rotate(45deg));
            }
            &:after {
                @include transform(translateY(0) rotate(-45deg));
            }
        }

        html.#{$no-css-class} & {

            &:before {
                content: '\e803';
                top: 0;
                color: $color-nav-secondary;
            }
        }
    }
    .nav--secondary {
        padding: 30px 0 0;
        margin: 20px 0 0;
    }
    .preheader--btn {
        margin: 20px 0;
        text-decoration: none;
        color: $color-white;
        padding: 10px;
        display: block;
        border: 2px solid $color-white;
        
        @include border-radius(5px);
    }
    .preheader--btn:first-of-type {
        margin-top: 0;
    }
}
