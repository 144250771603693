.quickmenu {
    border-bottom: 1px solid #999;
    margin: 0 0 50px 0;

    .hashtag-block {
        max-width: 66%;
        display: inline-block;
    }
    .back-button {
        text-transform: uppercase;
        display: inline-block;
        text-align: right;
        vertical-align: bottom;
        display: block;
        margin-top: -22px;

        @include font-size(14px);

        & a {                
            text-decoration: none;
            color: $color-text;
        }
        & a:hover {
            text-decoration: overline;
            color: $color-blue;
        }            
        .icon-left {
            vertical-align: middle;

            &:before {
                color: #000;

                @include font-size(12px);
            }            
        }
    }
}

#actu {
    .chapo figure:first-of-type {
        margin-left: 0;
    }
    .chapo p:first-of-type {
        clear: both;    
        
        @media #{$screen-l-rule-min} {
            clear: none;
        }
    }
    .framed {
        margin-top: 20px;
        
        ol {
            padding: 0;
            margin: 0;

            .block-1of2 {        
                padding: 10px;
                margin: 0;
                list-style: none;
            }
        }
    }
    .article--skin .ratio {
        background: transparent;
    }
}
.article--date {
    width: 16%; 
    white-space: nowrap;
    
    &.double-date, &.article--date-startend {
        width: 30%;
    }        
    .date {
        display: inline-block;
    }
    .icon-right {
        display: inline-block; 
        width: 30%; 
        transform: scaleY(1.5); 
        transform-origin: bottom; 
        font-weight: 900 !important; 
        margin-left: -10px;
    }
}