.page-menu {
    $blockContext: &;

    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
    border-radius: 8px;
    background-color: $color-gray-verylight;

    @media #{$screen-l-rule-min} {
        width: 260px;
    }

    &--title {
        @include font-size(16px);
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 0;
        padding-left: 0;
        border-left: 0;
        font-weight: 700;
        text-transform: uppercase;
    }

    &--list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    &--item {
        color: $color-blue;
    }

    &--item-active {
        font-weight: 700;
    }

    &--link {
        @include font-size(14px);
        text-decoration: none;

        html.no-touch &:focus,
        html.no-touch &:hover {
            text-decoration: underline;
        }
    }
}