.responsive--tabs {    
    @media #{$screen-l-rule-min} {
        margin: 40px 0;
    }
}

.responsive--tabs {
    .full--width {
        @media #{$screen-xl-rule-min} {
            margin-right: -40px;
        }
    }
}

.tabs--style {
    display: none;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    
    flex-flow: row wrap;
    justify-content: center;
    position: relative;
    max-width: none;
    border-bottom: none;
    
    @media #{$screen-m-rule-min} {
        display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox;  /* TWEENER - IE 10 */
        display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
    }
    @media screen and (min-width:720px\0) { //IE9+IE10
        display: flex;
    }
    
    .title, 
    h2, 
    h3 {
        font-family: $base-font-family;
    }
    li {
        position: relative;
        z-index: 1;
        display: block;
        margin: 0;
        -webkit-box-flex: 1;   /* OLD - iOS 6-, Safari 3.1-6 */
        -webkit-flex: 1;       /* Safari 6.1+. iOS 7.1+, BB10 */
        -ms-flex: 1;           /* IE 10 */
        flex: 1;               /* NEW, Spec - Firefox, Chrome, Opera */
        
	cursor: pointer;
        
        &:first-child {
            padding-left: 0;
            
            &:before {
                display: none;
            }
            &.active {
                .title {
                    &:before {
                        display: none;
                    }
                }
            }
        }
        &:last-child {
            .title {
                .text {
                    border: none;
                }
            }
        }
        
        &:hover {
            .title {
                color: $color-blue;
            }
        }
        &.active {
            z-index: 3;
            
            .title {
                font-weight: 700;
                color: $color-blue;
                background: $color-border;                
            }
        }
    }
    .title {
        letter-spacing: inherit;
        padding: 20px 10px;
        position: relative;
        text-align: center;
        color: $color-blue;
        font-weight: 500;
        margin: 0;
        
        
        @include font-size(15px);
        
        @media #{$screen-m-rule-min} {
            padding: 15px 0 5px;
            
            @include font-size(13px);
        }
        @media #{$screen-xl-rule-min} {
            padding: 15px 0 10px;
            
            @include font-size(17px);
        }
        &:after {
            display: none;
            margin: 0;
        }
        .text {
            display: block;
            padding: 0 2px;
            border-right: 1px solid $color-border;
        }
    }
    a {
        text-decoration: none;
    }
}

.tab--container {
    border-top: none;
    clear: both;
    width: 100%;
    background: $color-border;
    overflow: auto;
    margin-bottom: 30px;
    
    @media #{$screen-m-rule-min} {
        margin: 0;
    }    
    
    .block--number {
        background: #fff; 
        border-radius:10px;
        padding-top: 15px;
    }
}

.tab--content {
    padding: 10px;
    background: $color-border;
        
    @media #{$screen-l-rule-min} {
        padding: 20px;
    }
    .js & {
        display: none;
    }
}

.tabPannel { 
    color: #fff !important;
    border: none;
    border-top: 1px solid #fff;
    margin: 0;
    padding: 10px 20px;
    display: block;
    cursor: pointer;
    background: #bbb;
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
    @include font-size(16px);
    
    @media #{$screen-m-rule-min} {
        display: none; 
    }
    &:before {
        display: none;
    }
}
.tabPannel-active {
    color: #fff;
    background-color: $color-blue;
}
.tab--footer {
    background: $color-white;
    
    .tabs--style li {
        padding: 20px 0;
    }
    .tabs--style .title .icon:before {
        color: $color-yellow;
    }
    .tabs--style .title .text {
        color: $color-text;
        text-transform: uppercase;
        border-right: 0;
        display: inline-block;
        vertical-align: top;
        margin-top: 10px;
        @include font-size(13px);
    }
}
