.key-number-container {
    padding-bottom: 70px;
    
    @media #{$screen-m-rule-min} {
        padding-bottom: 140px;
    }
}
.block--key-number {
    background: url(../images/banner-key-number.jpg) no-repeat center center;
    background-size: cover;
    
    ul {
        text-align: center;
        padding: 0;
        margin: 0;
        list-style: none;
        color: #fff;
        font-weight: 700;
    }
    .numbers {
        padding: 80px 0 20px;
        
        @media #{$screen-m-rule-min} {
            padding: 110px 0 50px;
        }
        
        
        p {
            font-weight: 700;
            
            @include font-size(10px);
            
            @media #{$screen-m-rule-min} {
                @include font-size(14px);
            }
        }
        .text {
            position: absolute;
            top: 12px;
            left: 5%;
            width: 80%;
            
            @media #{$screen-m-rule-max} {
                @include center(y);
            }
            @media #{$screen-m-rule-min} {
                position: relative;
                width: auto;
                top: inherit;
                left: inherit;
            }
        }
    }
    li {
        float: left;
        position: relative;
        display: block;
        vertical-align: top;
        text-align: center;
        width: 50%;
        margin: 0;
        
        
        @media #{$screen-m-rule-min} {
            display: inline-block;
            float: none;
            width: 30%;
            margin: 0 5% 0 0;
            padding: 18px 22px 18px 10px;
        }
        @media #{$screen-l-rule-min} {
            width: 23%;
            margin: 0 1% 0 0;
        }
        @media #{$screen-xl-rule-min} {
            width: 18%;
            margin: 0 5% 0 0;
        }
        &:last-child {
            margin: 0;
        }
        &:nth-child(2n+2) {
            margin-top: 0;
            
            @media #{$screen-m-rule-min} {
                margin-top: 120px;
            }
        }
    }
    .title--number {
        color: $color-yellow;
        margin: 0;

        @include font-size(15px);
        
        @media #{$screen-m-rule-min} {
            @include font-size(45px);
        }
        @media #{$screen-l-rule-min} {
            margin: 0 0 15px;

            @include font-size(45px);
        }
    }
    img {
        width: 100%;
        
        @media #{$screen-m-rule-min} {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
        @media #{$screen-xl-rule-min} {
            width: auto;
        }
    }
}

