@charset "UTF-8";

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/fonts/icomoon.eot?eqchq8');
  src:  url('../fonts/icomoon/fonts/icomoon.eot?eqchq8#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/fonts/icomoon.ttf?eqchq8') format('truetype'),
    url('../fonts/icomoon/fonts/icomoon.woff?eqchq8') format('woff'),
    url('../fonts/icomoon/fonts/icomoon.svg?eqchq8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[data-icon]:before {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before,
%icomoon {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-xxl {
    color: $color-blue;
    line-height: 0;
    @include font-size(80px);

    @media #{$screen-l-rule-min} {
        @include font-size(180px);
    }
}
.icon-big {
    color: $color-blue;
    line-height: 0;
    @include font-size(40px);

    @media #{$screen-m-rule-min} {
        @include font-size(60px);
    }
}
.icon-medium {
    color: $color-blue;
    line-height: 0;
    @include font-size(30px);

    @media #{$screen-m-rule-min} {
        @include font-size(40px);
    }
}

.icon-design-zero-dechet:before {
  content: "\e900";
}
.icon-compost:before {
  content: "\e901";
}
.icon-download:before {
  content: "\e000";
}
.icon-left:before {
  content: "\e001";
}
.icon-link:before {
  content: "\e002";
}
.icon-mail:before {
  content: "\e003";
}
.icon-quote:before {
  content: "\e004";
}
.icon-right:before {
  content: "\e005";
}
.icon-expertise:before {
  content: "\e006";
}
.icon-job:before {
  content: "\e007";
}
.icon-file:before {
  content: "\e008";
}
.icon-volume:before {
  content: "\e009";
}
.icon-collectivity:before {
  content: "\e00a";
}
.icon-microphone:before {
  content: "\e00b";
}
.icon-print:before {
  content: "\e00c";
}
.icon-price:before {
  content: "\e00d";
}
.icon-radiator:before {
  content: "\e00e";
}
.icon-book:before {
  content: "\e00f";
}
.icon-setting:before {
  content: "\e010";
}
.icon-share:before {
  content: "\e011";
}
.icon-facebook:before {
  content: "\e012";
}
.icon-twitter:before {
  content: "\e013";
}
.icon-youtube:before {
  content: "\e014";
}
.icon-pinterest:before {
  content: "\e018";
}
.icon-linkedin:before {
  content: "\e019";
}
.icon-instagram:before {
  content: "\e01a";
}
.icon-dailymotion:before {
  content: "\e01b";
}
.icon-googleplus:before {
  content: "\e01c";
}
.icon-loupe:before {
  content: "\e015";
}
.icon-loupe2:before {
  content: "\e016";
}
.icon-player:before {
  content: "\e017";
}
.icon-wrench:before {
  content: "\e01d";
}
.icon-speech:before {
  content: "\e01e";
}
.icon-newspaper:before {
  content: "\e01f";
}
.icon-loupe3:before {
  content: "\e020";
}
.icon-leaf:before {
  content: "\e021";
}
.icon-marker:before {
  content: "\e022";
}
.icon-graphic:before {
  content: "\e023";
}
.icon-calendar:before {
  content: "\e024";
}
.icon-print-2:before {
  content: "\e025";
}
.icon-angle-right:before {
  content: "\e026";
}
.icon-angle-left:before {
  content: "\e027";
}
.icon-angle-down:before {
  content: "\e028";
}
.icon-angle-up:before {
  content: "\e029";
}
.icon-vimeo:before {
  content: "\e02a";
}
.icon-caret-down:before {
  content: "\e02b";
}
.icon-checkmark:before {
  content: "\e02c";
}
.icon-pause:before {
  content: "\e02d";
}
.icon-play:before {
  content: "\e02e";
}
.icon-star:before {
  content: "\e030";
}
.icon-business:before {
  content: "\e02f";
}
.icon-list-star:before {
  content: "\e031";
}
.icon-biodechet:before {
  content: "\e032";
}
.icon-centre-tri:before {
  content: "\e033";
}
.icon-collecte:before {
  content: "\e034";
}
.icon-encombrants:before {
  content: "\e035";
}
.icon-fire:before {
  content: "\e036";
}
.icon-urban:before {
  content: "\e038";
}
.icon-tri:before {
  content: "\e03a";
}
.icon-ordures:before {
  content: "\e03b";
}
.icon-arrow-expand:before {
  content: "\e03c";
}
.icon-verre:before {
  content: "\e037";
}
.icon-safety-cone:before {
  content: "\e039";
}
.icon-truck:before {
  content: "\e03d";
}
.icon-file-pdf-o:before {
  content: "\e03e";
}
.icon-alarm:before {
  content: "\e03f";
}
.icon-users-group:before {
  content: "\e040";
}
.icon-hour:before {
  content: "\e041";
}
.icon-paper:before {
  content: "\e042";
}
.icon-send:before {
  content: "\e043";
}
.icon-trash:before {
  content: "\e044";
}
.icon-target:before {
  content: "\e045";
}
.icon-project:before {
  content: "\e046";
}
.icon-tom-dechets:before {
  content: "\e047";
}
.icon-tom-bons-gestes:before {
  content: "\e048";
}
.icon-tom-parcours:before {
  content: "\e049";
}
.icon-tom-jeux:before {
  content: "\e04a";
}
.icon-tom-outils:before {
  content: "\e04b";
}
.icon-tom-icone:before {
  content: "\e04d";
}