.rsbtn {
    display: flex !important;
    height: 30px !important;
    z-index: 1 !important;
    margin-bottom: 0 !important;

    .rsbtn_play {
        display: flex !important;
        align-items: center !important;
        gap: 8px !important;
        float: none !important;
        font-family: $base-font-family !important;
        color: $color-blue !important;

        &::before {
            @extend %icomoon;
            @extend .icon-volume;
            @include font-size(22px);
        }
    }

    .rsbtn_left {
        display: flex !important;
        align-items: center !important;
        height: auto !important;
        float: none !important;
        background: transparent !important;

        .rsbtn_text {
            margin: 0 !important;
            padding-left: 0 !important;
            font-family: $base-font-family !important;
            font-size: 14px !important;
            background: transparent !important;
        }
    }

    .rsbtn_right {
        display: none !important;
    }

    .rsbtn_exp {
        width: 250px !important;
        height: auto !important;
        position: absolute !important;
        top: calc(100% + 10px) !important;
        left: -30px !important;
        float: none !important;
        padding: 20px 10px !important;
        border-radius: 8px !important;
        background-color: $color-gray-light !important;

        &::after {
            clear: both;
        }
    }

    .rsbtn_powered {
        top: auto !important;
        right: 6px !important;
        bottom: 6px !important;
    }
}