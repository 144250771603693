.search-result--container {
    &.solidarite {
        width: 200px;
        padding: 5px 0 5px 5px;
        background-color: $color-white;

        @media #{$screen-m-rule-min} {
            width: 300px;
            padding: 20px 0 20px 20px;
        }

        p {
            margin-bottom: 20px;

            &:last-child {
                margin: 0;
            }

            br {
                display: block;
            }
        }

        #tx-solr-results-list & {
            width: 100%;
            padding: 20px;
        }
    }
}
.search-result {
    &--container {

    }
    &--header {
        .solidarite & {
            display: table;
            width: 100%;
            font-weight: bold;
            font-family: $heading-font-family;

            .search-result--title {
                width: 70%;
                display: table-cell;
                vertical-align: middle;
                @include font-size(18px);


            }
        }
    }
    &--title {        
        .solidarite & {

        }
    }
    &--subtitle {
        .solidarite & {
            display: table-cell;
            vertical-align: middle;
            padding: 10px;

            text-align: center;
            color: $color-white;
            font-weight: bold;
            background-color: $color-map-subtitle;
            @include font-size(15px);
        }
    }
    &--country {
        .solidarite & {
            text-transform: uppercase;
            margin: 20px 0;
            @include font-size(18px);
        }
    }

    &--block {
        .solidarite & {
            p {
                padding: 0;

                &.align--right {
                    margin-bottom: 0;
                }
            }
        }
    }

}

.map--popin-content {
    width: 200px;
    padding: 5px 0 5px 5px;
    background-color: $color-white;

    @media #{$screen-m-rule-min} {
        width: 300px;
        padding: 20px 0 20px 20px;
    }
}