.syctom-presentation-container {
    padding: 0 0 100px;
}
.block--syctom-img {
    background: url(../images/banner-syctom-img.jpg) no-repeat center center;
    background-size: cover;
    
    .layout--maxwidth {
        height: auto;
        
        @media #{$screen-l-rule-min} {
           height: 640px; 
        }
    }
    
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
    }
    li {
        width: 100%;
        display: inline-block;
        margin: 0;
        
        @media #{$screen-m-rule-min} {
            margin: 0 15px;
            width: auto;
        }
    }
    .container--btn {
        width: 100%;
        padding: 80px 0 40px;
        
        @media #{$screen-m-rule-min} {
            padding: 100px 0 50px;
        }
        @media #{$screen-l-rule-min} {
            width: 100%;
            position: absolute;
            bottom: 60px;
            left: 0;
            padding: 0;
        }
    }
    .btn {
        width: 75%;
        padding: 15px 20px;
        margin: 0 0 20px;
        
        @include font-size(17px);
        
        @media #{$screen-m-rule-min} {
            margin: 0;
            width: auto;
            
            @include font-size(20px);
        }
    }
}

