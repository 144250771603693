table {
  border-collapse: collapse;
  margin: $small-spacing 0;
  table-layout: fixed;
  width: 100%;
}
tr {
    &:nth-child(2n+2) {
        td {
            background: $color-gray-light;
        }
    }
}
th {
  border-right: $base-border;
  font-weight: 600;
  padding: $small-spacing;
  text-align: left;
}

td {
  border-right: $base-border;
  padding: $small-spacing;
}

tr,
td,
th {
  vertical-align: middle;
}
