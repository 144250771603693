#skiplink {
    height: 0;
    background: $color-gray;
    display: none;
    
    ul {
        margin: 0 auto;        
        width: 100%;
        padding: 0 5%;

        @media #{$screen-xl-rule-min} {
            width: $layout-width-max;
            padding: 10px;
        }
    }
    li {
        list-style: none;
        display: inline-block;
    }
    a {
        text-decoration: none;
        padding: 0 10px 0 0;
        color: $color-white;
        
        @include font-size(12px);
        
        &:hover {
            text-decoration: underline;
        }
    }
    &.open {
        display: block;
    }
}

