.block--number {
    
    ul {
        text-align: center;
        padding: 0;
        list-style: none;
    }
    li {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        margin: 0 0 20px;
        
        @media #{$screen-l-rule-min} {
            width: 30%;
            margin: 0 4% 0 0;
        }
        
        &:last-child {
            margin: 0;
        }
    }
    &.block--number-mobile {
        ul {
            width: 90%;
            margin: 0 auto !important;
                      
            @media #{$screen-s-rule-min} {
                width: 70%;
            }          
            @media #{$screen-l-rule-min} {
                width: 50%;
            }
        }
        li {
            width: 100%;
            margin: 0 0 20px;
        }
    }
}
.block--number-content {
    padding: 0 12% 20px;
    text-align: center;
    position: relative;
    
    .number {
        color: $color-yellow;
        font-family: $heading-font-family;
        font-weight: bold;
        line-height: 1.1;
        
        @include font-size(28px);
        
        &.number--xs {
            @include font-size(25px);
        }
            
        @media #{$screen-s-rule-min} {
            @include font-size(38px);
           
            &.number--s {
                @include font-size(30px);
            }
        }
        @media #{$screen-m-rule-min} {
            @include font-size(48px);
            
            &.number--xs {
                @include font-size(25px);
            }            
            &.number--s {
                @include font-size(35px);
            }
        }
    }
    .unit {
        @include font-size(18px);
            
        @media #{$screen-s-rule-min} {
            @include font-size(24px);
        }
        @media #{$screen-m-rule-min} {
            @include font-size(30px);
        }
    }
    .text {
        font-weight: 500;
    }
    &:after {        
        position: absolute;
        right: 25px;
        bottom: 0;
        content: "";
        display: block;
        width: 3px;
        height: 80px;
        background: $color-yellow;
        
        @include transform(rotate(40deg));
    }
}
