.sections {
    padding-top: 50px;
}

.section {
    $blockContext: &;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    width: 100%;
    margin-bottom: 70px;

    @media #{$screen-m-rule-min} {
        margin-bottom: 90px;
    }

    &--container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 1200px;
        position: relative;
        padding: 0 5%;

        @media #{$screen-m-rule-min} {
            gap: 20px;
        }
    }

    &--header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -20px;
        padding: 20px 40px;
        border-radius: 0 0 10px 10px;
        background-color: $color-white;

        @media #{$screen-m-rule-min} {
            max-width: 70%;
        }
    }

    &--title {
        @include font-size(30px);
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        border-left: 0;
        font-weight: 400;
        color: $color-title;
        text-align: center;
        text-transform: uppercase;

        &:after {
            content: "";
            display: flex;
            width: 40px;
            height: 2px;
            margin-top: 15px;
            background-color: $color-yellow;
        }
    }

    &--content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }

    &--grid {
        display: grid;
        grid-template-rows: 1fr auto;
        gap: 2px;

        @media #{$screen-m-rule-min} {
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 2fr 1fr;
            grid-template-areas: "a b" "a c";
        }
    }

    &--grid-reverse {
        @media #{$screen-m-rule-min} {
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr 2fr;
            grid-template-areas: "b a" "c a";
        }
    }

    &--grid-item {
        &:not(.no--mobile) {
            display: flex;
        }

        &:nth-child(1) {
            @media #{$screen-m-rule-min} {
                grid-area: a;
            }
        }

        &:nth-child(2) {
            @media #{$screen-m-rule-min} {
                grid-area: b;
            }
        }

        &:nth-child(3) {
            @media #{$screen-m-rule-min} {
                grid-area: c;
            }
        }
    }

    &--read-more {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 4px;
        margin-bottom: 0;
        color: $color-blue;
    }

    &--read-more-link {
        font-weight: 700;
    }

    &--bg-white {
        background-color: $color-white;
    }

    &--bg-grey {
        background-color: $color-gray-light;
    }

    &--bg-grey-dark {
        background-color: $color-gray;

        #{$blockContext}--read-more,
        #{$blockContext}--read-more-link {
            color: $color-white;
        }
    }

    &--bg-grey,
    &--bg-grey-dark {
        padding-top: 20px;
        padding-bottom: 20px;

        @media #{$screen-m-rule-min} {
            padding-bottom: 40px;
        }

        #{$blockContext}--container {
            gap: 20px;

            @media #{$screen-m-rule-min} {
                gap: 40px;
            }
        }
    }

}