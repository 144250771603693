// paths
$path-absolute: '/integration/front/';

$lib-css3pie-path: $path-absolute + 'libs/ie8/css3-pie/1.0.0/PIE.htc';
$lib-backgroundsize-path: $path-absolute + 'libs/ie8/backgroundsize/0.2.0/backgroundsize.min.htc';


// html classes
$class-ie8: 'is-ie8';


// selectors
$homepage-selector: 'body.home';
$header-transparent-selector: '#header.transparent';


// colors
$color-black: #000;
$color-white: #fff;

$color-text: #3b3b3b;
$color-title: #414141;
$color-border:#e6e6e6;
$color-bg: #f3f4f3;

$color-gray: #676767;
$color-gray-light: #d2d9d3;
$color-gray-verylight: #f3f6f7;
$color-blue-light: #3f799f;
$color-blue: #065c7f;
$color-blue-dark: #054159;
$color-yellow: #fdca17;
$color-red: #e71b1b;

$color-map-subtitle: #e86722;

$base-color-background: $color-white;
$base-color-font: $color-gray;
$color-action: $color-blue;



// Typography
$base-font-family: 'Quicksand', sans-serif;
$heading-font-family: 'Quicksand', sans-serif;
$link-font-family: 'Quicksand', sans-serif;
$serif-font-family: 'Quicksand', sans-serif;
$serif-italic-font: 'Quicksand', sans-serif;

// sizes
$base-font-size: 15px;
$h1-font-size: 46px;
$h2-font-size: 24px;
$h3-font-size: 22px;
$h4-font-size: 18px;
$h5-font-size: 13px;
$h6-font-size: 11px;


$h1-fontsize-mobile: 35px;
$h2-fontsize-mobile: 24px;
$h3-fontsize-mobile: 20px;
$h4-fontsize-mobile: 16px;

$screen-s-header-height: 60px;
$screen-l-header-height: 120px;
$screen-xxl-header-height: $screen-l-header-height + 20px;

$screen-s-nav-height: 60px;
$screen-l-nav-height: 120px;

$screen-s-footer-height: 30px;
$screen-l-footer-height: 90px;


// line-heights
$base-line-height: 1.5;
$heading-line-height: 1.2;


// margins/paddings
$screen-s-margin-h: 30px;
$screen-m-margin-h: 40px;
$screen-l-margin-h: 50px;

$screen-s-margin-v: $screen-s-margin-h;
$screen-m-margin-v: $screen-m-margin-h;
$screen-l-margin-v: $screen-l-margin-h + 10px;


// durations
$hover-transition-duration: .35s;
$overlay-animation-duration: .4s;


// z-indexes
$base-zindex: 0;
$zindex-markers: $base-zindex + 1;
$zindex-slider: $zindex-markers + 1;
$zindex-submenu: $zindex-slider + 1;
$zindex-loader: $zindex-submenu + 1;
$zindex-footer: $zindex-loader + 1;
$zindex-nav: $zindex-footer + 1;
$zindex-header: $zindex-nav + 2;
$zindex-colorbox: $zindex-header + 1;

