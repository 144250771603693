.box--contact-content {
    position: relative;
    
    .contact--img, 
    .contact--text {
        float: left;
    }
    .contact--img {
        overflow: hidden;
        width: 38%;
        margin: 0 5% 0 0;
        position: relative;
    }
    .contact--text {
        width: 57%;
    }
    .title {
        padding: 0;
        margin: 0 0 10px;
        font-weight: 900;
        color: $color-text;
        
        font-family: $base-font-family;
            
        @media #{$screen-l-rule-min} {
            @include font-size(17px);
        }
    }
    .statut {
        font-style: italic;
        font-weight: 500;
        margin: 0 0 30px;
        
        @media #{$screen-l-rule-min} {
            @include font-size(13px);
        }
    }
    .ratio {
        display: block;
        width: 100%;
        padding-top: 100%;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
    }
}

