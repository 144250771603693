.title--block-full {
    $margin: 4%;
    $width-1of4: calculateBlockWidth($margin, 4);
    $width-1of2: calculateBlockWidth($margin, 2);

    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    border-left: 0;
    color: $color-title;
    text-align: center;
    text-transform: uppercase;

    .text {
        @include border-radius(10px);
        @include font-size(30px);
        display: inline-block;
        position: relative;
        margin-top: -20px;
        padding: 20px 8% 40px;
        font-weight: normal;
        background-color: $color-white;

        @media #{$screen-m-rule-min} {
            //@include font-size(40px);
            margin-top: -36px;
        }

        @media #{$screen-l-rule-min} {
            padding: 30px 50px 50px;
        }

        &:after {
            @include center(x);
            content: "";
            display: inline-block;
            width: 45px;
            height: 2px;
            position: absolute;
            bottom: 24px;
            left: 50%;
            background-color: $color-yellow;

            @media #{$screen-m-rule-min} {
                bottom: 30px;
            }
        }
    }
}

// .title--fix {
//     position: relative;
//     z-index: 1;

//     .title--block-full {
//         width: 100%;
//         position: absolute;
//         top: -30px;
//         left: 0;
//         z-index: 1;

//         &:after {
//             display: none;
//         }
//     }
// }