* {
    box-sizing: border-box;

    &:after,
        &:before {
        box-sizing: border-box;
    }


    html.minisite & {
        // @include custom-selection($color-minisite-green);
    }

    html.is-mobile &:not(input):not(textarea) {
        //@include prevent-selection;
    }
}

html,
body {
    // WARNING: this prevents :active styles from being applied on mobile
    // when document is higher than viewport
    height: 100%;
    width: 100%;

    background: $base-color-background;
    
    font-family: $base-font-family;
    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-size: $base-font-size; // let's use rem units
}

body {
    //color: $base-color-font;
    //line-height: $base-line-height;

    // @include font-size($base-font-size); // added on <html> in order to use rem units
    //@include font-smoothing;


    // Avoid visible rectangle when taping on iOS/Android
    //@include webkit-no-tap-highlight;
}
.layout--maxwidth {
    width: 100%;
    padding: 0 5%;
    position: relative;
    
    @media #{$screen-xl-rule-min} {
        width: $layout-width-max;
        margin: 0 auto;
        padding: 0;
    }
}

.scrollable--wrapper {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden !important;
}
.scrollable {
    overflow-x: hidden;
    overflow-y: auto;

    &.scrollable-xy {
        overflow: auto;
    }

    html.is-ios & {
        -webkit-overflow-scrolling: touch;
    }

    > * {
        // WARNING: avoid it, it can create bad stacking contexts
        // @include transform(translateZ(0));
    }
}


// reset titles' font-weight

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}



// Remove the gap between audio, canvas, iframes, images, videos and the bottom of their containers
// https://github.com/h5bp/html5-boilerplate/issues/440

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}



// Set max-width for these elements

audio,
canvas,
iframe,
img,
svg,
video,
picture {
    max-width: 100%;
}



// Preserve ratio of images and pictures

img,
picture {
    margin: 0;
    height: auto;

    // bugfix; check the specific class appended for IE8
    /*html.#{$class-ie8} & {
        width : auto;
    }*/
}



// Remove default fieldset styles.

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}



// Allow only vertical resizing of textareas.

textarea {
    resize: vertical;
}



// A better looking default horizontal rule

hr {
    display: block;
    height: 1px;
    //border-bottom: $base-border;
    border-left: none;
    border-right: none;
    border-top: none;
    //margin: $base-spacing 0;
    padding: 0;
}

blockquote {
    position: relative;
    margin: 30px 0;
    padding: 30px 50px;
    font-style: italic;
    font-weight: 500;
    line-height: 1.4;
    
    @media #{$screen-m-rule-min} {
        padding: 30px 60px;
    }
    @media #{$screen-l-rule-min} {
        padding: 30px 80px;
    }
    
    &:before, 
    &:after {
        @extend %icomoon;
        @extend .icon-quote;
        @include font-size(40px);
        position: absolute;
        color: $color-yellow;

        @media #{$screen-m-rule-min} {
            @include font-size(50px);
        }
        @media #{$screen-l-rule-min} {
            @include font-size(70px);
        }
    }
    &:before {
        left: 0;
        top: 0;
    }
    &:after {
        bottom: 0;
        right: 0;
        
        @include transform(rotate(180deg));
    }
    .framed & {
        
        p {  
            
            @media #{$screen-l-rule-max} {
                clear: both;
            }
        }
    }
}
.bold {
    font-weight: bold !important;
}
.bg--blue {
    color: $color-white;
    background-color: $color-blue-dark;
    
    .icon {
        color: $color-yellow;
    }
}
.bg--gray {
    color: $color-white;
    background-color: $color-gray;
}
.bg--gray-light {
    background-color: $color-gray-light;
}
.bg--gray-xlight {
    background-color: $color-border;
}
.bg--white {
    background-color: $color-white;
}
.color-text {
    color: $color-text;
}
.align--center {
    text-align: center;
}
.align--right {
    text-align: right;
}
.float--left {
    float: left;
}
.float--right {
    float: right;
}
.margin--auto {
    margin: 0 auto;
}
.plr {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    text-indent: -9999px;
    visibility: hidden;
}
.no--mobile {
    @media #{$screen-m-rule-max} {
        display: none;
    }
}
.ul--rte, 
.ol--rte {
    list-style: none;
    //padding: 0 0 0 30px;
    margin: 0 0 30px;
    
    .content--tm & {
        padding-left: 0;
        clear: none;
    } 
       
    .box & {
        padding: 0;
    }
    li {
        position: relative;
        margin: 0 0 10px;
        font-weight: 500;
        line-height: 1.4;
        padding: 0 0 0 10px;
        
        &:before {
            content: "\2022";
            position: absolute;
            left: 0;
            top: 0;
            color: $color-yellow;
            display: inline-block;
        
            .content--tm & {
            
                position: relative;
                left: 30px;
                margin-right: 35px;
            }
        }
    }
    & .ul--rte {
        margin-top: 10px;
    }
}
.ol--rte {
    li {
        padding-left: 20px;
        counter-increment: custom-counter; //on incremente le compteur de chaque li
        
        &:before {
            content: counter(custom-counter); 
            margin: 0 10px 0 0;
            font-weight: bold;
            color: $color-blue;
            
            @include font-size(16px);
            @include transition(all ease 0.25s);
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 12px;
            top: 3px;
            width: 2px;
            height: 15px;
            background: $color-blue;
            
            @include transform(rotate(10deg));
        }
    }
}
.ul--icons {
    margin: 0;
    padding-left: 20px;

    li {
        list-style: none;

        @include font-size(13px);

        .icon {
            vertical-align: middle;
            margin-right: 10px;

            @include font-size(30px);
        }
    }
}
.framed {
    color: $color-blue;
    padding: 30px;
    margin: 40px 0;
    background-color: $color-gray-verylight;
    position: relative;
    
    @include border-radius(5px);
    
    .title {
        text-transform: uppercase;
    }
    &.nopadding {
        padding: 2px 30px;
    }
    /*h2, h3 {
        padding-left: 0;
    }*/
}   
figure {
    position: relative;
    margin: 0 20px 20px;
    display: inline-block;
} 
.figcaption--main {
    width: 90%;
    position: absolute;
    padding: 5px 10px;
    bottom: 0;
    background-color: $color-white;
    
    @include border-radius(5px 5px 0 0);
    @include center(x);
}
.figure--title {
    font-style: italic;
    font-weight: 500;
    margin: 0;
    
    @include font-size(14px);
}
.copyright {
    font-style: italic;
    font-weight: 500;
    margin: 0;
    
    @include font-size(9px);
}
.progress-bar {
    padding: 30px 0;

    .title {
        color: $color-blue;
        display: inline-block;
        vertical-align: top;
        padding-top: 12px;
    }
    .progress-bar--block {
        display: inline-block;
        background-color: $color-white;
        border-radius: 5px;
        height: 38px;
        width: 72%;
        margin-left: 20px;

        .progress-bar--status {
            float: left;
            display: inline-block;
            background: $color-blue;
            border-radius: 5px;
            height: 38px;
        }
    }
}
p span.title {
    font-weight: bold;
    color: $color-blue;
}

p.alignc  {
    text-align: center;
}