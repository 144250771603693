#breadcrumb {
    margin: 20px 0;
    
    ul, ol {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    li {
        display: inline;
        vertical-align: middle;
    }
    .active {
        color: $color-text;
        
        &:after {
            display: none;
        }
    }
    a {
        display: inline-flex;
        flex-direction: row-reverse;
        align-items: center;
        position: relative;
        color: $color-blue;
        
        &:before {
            @extend .icon-angle-right;
            @extend %icomoon;
            display: inline-block;
            vertical-align: middle;
            padding: 0 5px;
            color: $color-text;
        }
        
        html.no-touch & {
            &:hover {
                text-decoration: underline;
                
                &:after {
                    opacity: 1;
                    bottom: -2px;
                }
            }
        }
    }
    
}
