.document {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    background-color: $color-gray-verylight;

    @media #{$screen-m-rule-min} {
        flex-direction: row;
        align-items: flex-start;
        padding: 40px;
    }

    &--image {
        width: 200px;
        height: auto;
    }
}