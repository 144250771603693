// Other sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: calc($base-spacing / 2);

// Border
$base-border-color: $color-gray-light;
$base-border: 1px solid $base-border-color;
$color-border-nav: #646566;
$border-nav: 1px solid $color-border-nav;
$border-transparent: 1px solid rgba(255,255,255,0.5);

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($color-action, $lightness: -5%, $alpha: -0.3);
