#col--right {
    margin-top: 60px;
}

.col--right .alignc{
    text-align: center;
}

.col--right-title {
    position: relative;
    text-transform: uppercase;
    text-align: center;
    font-weight: normal;
    margin: 0 0 20px;
    padding: 0 0 15px;
    color: $color-text;
    
    @include font-size(20px);
    
    &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        width: 40px;
        height: 2px;
        background: $color-yellow;
        
        @include center(x);
    }
    .notepad & {
        margin-bottom: 10px;
    }
}

.box {
    margin-bottom: 20px;
}

.box--bg {
    @include border-radius(5px);
    position: relative;
    padding: 15px;
    background-color: $color-bg;
}

.box--infos {
    img {
        padding: 20px;
    }

    h3 {
        text-align: left;
    }

    iframe {
        margin-bottom: 25px;
    }

    .ul--rte {
        text-align: left;
        padding: 0 0 0 15px;
    }

    .btn {
        margin-top: 5px;
        text-align: center;
    }

    .box--bg {
        padding-bottom: 4px;
    }

    .box--infos-event {
        position: relative;
        margin-bottom: 20px;

        .article--date {
            height: inherit;
        }

        img {
            padding: 0;
        }
    }

}

.box--hr {
    border-bottom: 1px solid $color-gray-light; 
    padding-bottom: 10px;
}

.col--right-search {
    padding: 0 10px;
    margin-top: 40px;
    
    form {
        position: relative;
    
        input[type="search"] {
            border: none;
            box-shadow: none;
            padding: 10px 15px;
            font-family: $link-font-family;

            @include border-radius(5px);
            @include font-size(14px);

            &::-webkit-input-placeholder {
                font-style: italic;
                font-weight: bold;
                color: $color-text;
            }

            &:-moz-placeholder { /* Firefox 18- */
                font-style: italic; 
                font-weight: bold;
                color: $color-text;
            }

            &::-moz-placeholder {  /* Firefox 19+ */
                font-style: italic; 
                font-weight: bold;
                color: $color-text;
            }

            &:-ms-input-placeholder {  
                font-style: italic; 
                font-weight: bold;
                color: $color-text;
            }
        }
        .submit--search {
            position: absolute;
            right: 3px;
            top: 50%;
            margin-top: -13px;
            background-color: $color-white;
            width: 26px;
            height: 26px;
            line-height: 18px;
            
            .icon {
                color: $color-yellow;
                
                @include font-size($base-font-size);
            }
        }
    }
}
.notepad--link {
    position: absolute;
    top: 45%;
    right: 25px;
    
    
    @media #{$screen-m-rule-min} {
        right: 4px;
    }
    @media #{$screen-l-rule-min} {
        right: 25px;
    }
    
    @include center(y);
    a {
        $width: 24px;
        
        display: block;
        width: $width;
        height: $width;
        line-height: $width;
        text-align: center;
        text-decoration: none;
        color: $color-text;
        
        background: $color-yellow;
        
        @include border-radius($width);
        @include transition(all ease 0.5s);
        
        &:after {
            content: "";        
            display: block;
            width: $width;
            height: $width;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            background: $color-blue;
            
            z-index: 1;
            
            @include border-radius($width);
            @include transform(scale(0));
            @include transition(all ease 0.5s);
        } 
        html.no-touch & {
            &:hover {
                color: #fff;
                &:after {
                    opacity: 1;
                    
                    @include transform(scale(1));
                }
            }
        }
    }
    .number {
        position: relative;
        z-index: 2;
    }
}
