.page-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 30px;

    @media #{$screen-l-rule-min} {
        padding-right: 10%;
        padding-left: 10%;
    }

    > * {
        margin-top: 0;
        margin-bottom: 0;
    }
}