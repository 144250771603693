.box-testimony {
    width: 100%;
    padding: 40px 30px;
    border-radius: 8px;
    background-color: $color-gray-light;

    &--title {
        @include font-size(16px);
        margin-top: 0;
        font-weight: 700;
    }
}