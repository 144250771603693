.box-dialog {
    width: 100%;
    padding: 40px 30px;
    border: 2px solid $color-blue;
    border-radius: 8px;

    > *:nth-last-child(1) {
        margin-bottom: 0;
    }

    &--title {
        @include font-size(16px);
        margin-top: 0;
        font-weight: 700;
    }

    &--subtitle {
        @include font-size(16px);
    }
}