h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $heading-font-family;
    font-weight: 400;
    @include font-size($base-font-size);
    line-height: $heading-line-height;
    margin: 0 0 $small-spacing;
}

h1 { 
    @include font-size($h1-fontsize-mobile);
    
    @media #{$screen-m-rule-min} {
        @include font-size($h1-font-size); 
    }
}
h2 { 
    @include font-size($h2-fontsize-mobile);
    
    @media #{$screen-m-rule-min} {
        @include font-size($h2-font-size); 
    }
}
h3 { 
    @include font-size($h3-fontsize-mobile);
    
    @media #{$screen-m-rule-min} {
        @include font-size($h3-font-size); 
    }
}
h4 { 
    @include font-size($h4-fontsize-mobile);
    
    @media #{$screen-m-rule-min} {
        @include font-size($h4-font-size); 
    }
}
h5 { 
    @include font-size($h5-font-size); 
}
h6 { 
    @include font-size($h6-font-size); 
}

h1.no-popin,
h2 {
    margin: 30px 0;
}
// h2 {
//     font-weight: 700;
    
//     &:after {
//         content: "";
//         display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
//         display: -ms-flexbox;  /* TWEENER - IE 10 */
//         display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
//         display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
//         width: 40px;
//         height: 2px;
//         margin-top: 17px;
//         background: $color-blue;
//     }
// }
h2 {
    padding-left: 16px;
    border-left: 4px solid $color-yellow;
    color: $color-blue;
}
p {
    margin: 0 0 20px;
    font-weight: 500;
    line-height: 1.4;
}
h3, 
h4{
    font-weight: 700;
    margin: 30px 0;
    padding: 0 0 0 15px;
    
    .framed & {
        margin: 20px 0;
    }
}
h3 {
    color: $color-blue;
}
h4 {
    font-weight: normal;
    font-style: italic;
    font-family: 'Arvo', serif;
    color: $color-title;
}
b,
strong,
.bold {
    font-weight: bold;
}

a {
    cursor: pointer;
    color: $color-action;

    @include transition(all ease 0.5s);
    
    html.no-touch & {
        &:hover {
            text-decoration: none;
        }
    }
}

.h1--subtitle {
    margin: -30px 0 60px;
}
.h3--nopadding {
    padding-left: 0;
    margin: 0 0 20px 0;
}
.h3--small {
    padding-left: 0;
    margin: 20px 0;
    @include font-size(16px);
}

.ul--nopadding {
    padding-left: 0;
    margin-bottom: 30px;
    
    @media #{$screen-m-rule-min} {
        margin-bottom: 40px;
    }
}

#sommaire {
    padding: 0;

    li {
        width:100%; 
        padding: 6px; 
        display: inline-block;
        vertical-align: top;
        
        @media #{$screen-m-rule-min} {
            width:49%;
        }
        
        h3 {
            padding-left: 0;
            margin: 20px 0;
        }      
        img {
            width: 100%;
        }
        .filter--hover {
            background: rgba(6, 92, 127, .5);
        }
    }
}

#fiche {
    h1 {
        margin-bottom: 10px;
    }
    h4 {
        margin-top: 0;
        font-style: normal;
    }
    .tab--container h2 {
        text-transform: uppercase;
        @include font-size(22px);
    }
    .block-openclose {        
        ul {
            font-weight: 500;
            line-height: 1.4;
            padding: 0;
            margin: 0;
            
            li {
                list-style: none;
            }
        }
        .content {
            h3 {
                margin-top: 0;
            }
            .planning-block {
                display: inline-block; 
                width: 65%;
                margin: 0 0 20px 30px;
            }
        }
    }
}