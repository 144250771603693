.box--slider-content {
    padding: 0 20px;
    
    .swiper-button-next, 
    .swiper-button-prev {
        margin-top: -12px;
        height: 25px;
        top: 50%;
        
        .icon {
            @include font-size(25px);
            
            &:before {
                display: block;
            }
        }
    }
    .swiper-button-prev {
        left: 5px;
    }
    .swiper-button-next {
        right: 0;
    }
}

.swiper-container {
    position: relative;
    display: block;
    cursor: pointer;
    
    .icon {
        opacity: 0.8;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;
        
        @include font-size(40px);
        @include center(xy);
        
        @media #{$screen-m-rule-min} {
            @include font-size(60px);
        }
        @media #{$screen-l-rule-min} {
            @include font-size(80px);
        }
        @media #{$screen-xl-rule-min} {
            @include font-size(100px);
        }
    }
}

#col--main .box--slider {
    &.float--left {
        margin: 0 20px 20px 0;
        width: 60%;
    }
    &.float--right {
        margin: 0 0 20px 20px;
        width: 60%;
    }
}
