.testimony--box {
    .ratio {
        display: none;
    }
}

.testimony--content {
    position: relative;
    overflow: hidden;

    &:after {
        @include transition(all ease 0.35s);
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: $color-blue-dark;
    }

    html.no-touch & {
        &:focus,
        &:hover {
            .caption {
                background-color: $color-blue;
            }

            .icon {
                opacity: 1;
            }
        }
    }

    .testimony--link,
    > a {
        display: block;
        height: 100%;
        position: relative;
        z-index: 3;
        text-decoration: none;
    }

    img {
        width: 100%;
        height: 100%;
		object-fit: cover;
    }

    .icon {
        @include font-size(40px);
        @include transition(all ease 0.5s);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        margin: auto;
        color: $color-white;
        opacity: 0.7;

        @media #{$screen-m-rule-min} {
            @include font-size(30px);
        }

        @media #{$screen-l-rule-min} {
            @include font-size(90px);

            &.icon-quote {
                top: 5%;
                left: 20%;
            }
        }

        .block-2of3 & {
            @media #{$screen-m-rule-min} {
                @include font-size(70px);
            }

            @media #{$screen-l-rule-min} {
                @include font-size(120px);
                top: 33%;
            }
        }

    }

    .caption {
        @include transition(all ease 0.5s);
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        padding: 10px;
        color: $color-white;
        background-color: $color-blue-dark;

        @media #{$screen-m-rule-min} {
            padding: 10px 20px;
        }
        @media #{$screen-l-rule-min} {
            padding: 15px 20px;
        }

        .block-2of3 & {
            @media #{$screen-l-rule-min} {
                @include font-size(15px);
            }
        }
    }

    .title {
        @include font-size(14px);
        color: $color-white;
        margin: 0 0 8px;
        padding: 0;
        font-weight: 700;

        @media #{$screen-m-rule-min} {
            @include font-size(13px);
        }

        @media #{$screen-l-rule-min} {
            @include font-size(16px);
        }

        .block-2of3 & {
            @include font-size(16px);

            @media #{$screen-l-rule-min} {
                @include font-size(22px);
            }
        }
    }

    p {
        margin: 0;
        line-height: 1;
    }

    .name {
        @include font-size(12px);
        margin: 0 10px 0 0;
        font-weight: 700;

        @media #{$screen-m-rule-min} {
            @include font-size(12px);
        }

        @media #{$screen-l-rule-min} {
            @include font-size(15px);
        }

        .block-2of3 & {
            @include font-size(15px);

            @media #{$screen-l-rule-min} {
                @include font-size(21px);
            }
        }
    }

    .statut {
        @include font-size(11px);
        display: inline;
        font-style: italic;

        @media #{$screen-m-rule-min} {
            @include font-size(12px);
            display: none;
        }

        @media #{$screen-l-rule-min} {
            display: inline;
        }

        .block-2of3 & {
            display: inline;

            @media #{$screen-l-rule-min} {
                @include font-size(15px);
            }
        }
    }
}
