.popin--link {
    text-decoration: none;
}
#lightcase-case, 
#lightcase-solr {
    text-shadow: none;
    position: fixed;
    z-index: 2002;
    top: 50%;
    left: 50%;
    font-family: arial, sans-serif;
    font-size: 13px;
    line-height: 1.5;
    text-align: left;
}
#lightcase-solr {
    border: 2px solid $color-border;
}
#lightcase-info {
    width: 100%;
    padding: 0 20px 20px;
    
    #lightcase-title {
        font-weight: bold;
        color: $color-text;
        @include font-size(14px);
    }
    #lightcase-sequenceInfo {
        position: absolute;
        right: 20px;
    }
    #lightcase-caption {
        color: $color-text;
        font-style: italic;
    }
}
#lightcase-case:not([data-lc-type=error]) #lightcase-content {
    box-shadow: none;
}
#lightcase-nav a[class*='lightcase-icon-'] {
    font-size: inherit;
}
#lightcase-nav a[class*='lightcase-icon-'], 
#lightcase-nav a[class*='lightcase-icon-']:focus {
    color: rgba($color-text, 0.6);
    opacity: 1;
}
#lightcase-nav a[class*='lightcase-icon-']:hover {
    text-shadow: none;
    color: #000;
}

#lightcase-nav {
    .lightcase-icon-close {
        top: 0;
        bottom: inherit;
        padding: 20px;
        text-align: center;
        bottom: inherit;
        background: none;
        -webkit-transition: all ease 0.2s;
        -moz-transition: all ease 0.2s;
        transition: all ease 0.2s;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 17px;
            left: 7px;
            height: 2px;
            width: 25px;
            background-color: #fff;
            -moz-transition: all ease-out 0.2s;
            -webkit-transition: all ease-out 0.2s;
            -o-transition: all ease-out 0.2s;
            -ms-transition: all ease-out 0.2s;
            transition: all ease-out 0.2s;
        }
        &:before {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }
        &:after {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
    }
    a[class*='lightcase-icon-'] {
        &.lightcase-icon-prev, 
        &.lightcase-icon-next {
            top: 51%;
            right: 20px;
            left: inherit;
            bottom: inherit;
            width: 38px;
            height: 20px;
            background: none;

            &:before,
            &:after {
                content: "";
                position: absolute;
                left: 10px;
                width: 15px;
                height: 2px;
                background-color: #fff;
                -webkit-transition: all ease-out 0.2s;
                -moz-transition: all ease-out 0.2s;
                transition: all ease-out 0.2s;
            }
            &:before {
                top: 3px;
            }
            &:after {
                bottom: 5px;
            }
        }
        &.lightcase-icon-prev {
            right: 15px;
            padding: 0 0 40px;

            &:before {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
            &:after {
                bottom: 25px;
                
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
        &.lightcase-icon-next {
            right: 15px;
            
            &:before {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            &:after {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
        }
    }
    
   
}
#lightcase-case:not([data-lc-type=error]) #lightcase-content .lightcase-contentInner .lightcase-inlineWrap {
    padding: 20px;
}
#lightcase-overlay {
    //opacity: 1 !important;
    background: #999 !important;
}