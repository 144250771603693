.bx-wrapper {

    .bx-controls-direction {
        $background-hover-color: rgba($color-white, .7);

        a {
            z-index: 1;

            position: absolute;
            top: 50%;
            width: $screen-s-controls-direction-width;
            height: $screen-s-controls-direction-heigth;

            margin-top: -($screen-s-controls-direction-heigth / 2);

            outline: 0;
            text-indent: -9999px;
            text-decoration: none;

            // border: 1px solid $color-white;
            color: $color-white;

            @include transition(
            background $hover-transition-duration ease,
            color $hover-transition-duration ease
            );

            @media #{$screen-l-rule-min} {
                width: $screen-l-controls-direction-width;
                height: $screen-l-controls-direction-heigth;
                margin-top: -($screen-l-controls-direction-heigth / 2);
            }

            &:before {
                @include icon-fullspace($screen-s-controls-direction-heigth);

                font-family: 'icons';
                text-indent: 0;
                @include font-size(20px);

                @media #{$screen-l-rule-min} {
                    @include icon-fullspace($screen-l-controls-direction-heigth);
                    @include font-size(30px);
                }
            }

            &.disabled {
                display: none;
            }

            html.csstransforms & {
                &:after {
                    content: '';
                    z-index: -1;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;

                    @include transition(all $hover-transition-duration ease-out);
                    @include transform(scaleX(0));
                }
            }
        }

        .bx-prev {
            left: 0;
            border-left-width: 0;

            &:before {
                content: '\e802';
            }

            &:after {
                @include transform-origin(100% center);
            }
        }

        .bx-next {
            right: 0;
            border-right-width: 0;

            &:before {
                content: '\e803';
            }

            &:after {
                @include transform-origin(0 center);
            }
        }
    }
}