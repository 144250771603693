.box--mission {
    h2 {
        text-align: left;
        padding: 0 14px;
        @include font-size(16px);
        
        @media #{$screen-l-rule-min} {
            @include font-size(20px);
        }
        &:after {
            display: none;
        }
    }
    .box--bg {
        padding: 20px;
        background: $color-gray-light;
    }
    .mission--ul {
        margin: 0;
        padding: 0 14px;

        li {
            list-style: none;
            line-height: 180%;
        }
        li:first-letter {
            text-transform: uppercase; 
        }
        li.mission--ul-selected {
            color: $color-blue;
            font-weight: 600;
            margin-left: -24px;
            
            @media #{$screen-m-rule-min} {
                margin-left: -14px;
            }
            @media #{$screen-l-rule-min} {
                margin-left: -24px;
            }

            &:before {
                content: " ";
                width: 20px;
                height: 2px;
                display: inline-block;
                background: $color-blue;
                vertical-align: middle;
                
                @media #{$screen-m-rule-min} {
                    width: 10px;
                }
                @media #{$screen-l-rule-min} {
                    width: 20px;
                }
            }
        }
        ul {
            margin: 10px 0;
            font-weight: normal;
            
            @media #{$screen-m-rule-min} {
                padding-left: 30px;
            }
            @media #{$screen-l-rule-min} {
                padding-left: 40px;
            }
        }
            
        .mission--li-selected {
            color: $color-blue;
            font-weight: 600;
            list-style: initial;
        }
    }
    ul ul ul {
        padding-left: 30px;
    }
}
.mission--block {
    margin-top: 20px;

    .block-1of2:first-of-type {
        text-align: center;
        padding: 30px;
        margin-bottom: 10px;

        @media #{$screen-l-rule-min} {
            padding: 50px;
            margin-bottom: 0;
        }
    }
    .block-1of2:last-of-type {
        padding-left: 20px;
    }
}

.box--white {
    @include border-radius(15px);
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 20px;
    margin: 10px;
    background-color: $color-white;

    @media #{$screen-xl-rule-min} {
        width: 46%;
    }

    h3 {
        @include font-size (18px);
    }

    ul {
        li {
            &::before {
                color: $color-blue;
            }
        }
    }
}

.box--job {
    display: inline-block;
    width: 100%;
    vertical-align: top;

    @media #{$screen-m-rule-min} {
        width: 45%;
        margin-right: 4%;
    }

    ul {
        padding-left: 0;
        margin-top: 0;

        li {
            list-style: none;
            line-height: 180%;
            color: $color-blue;
        }
    }
}

.box--cb {
	background-color: $color-blue-dark;

	.col--right-title {
		color: $color-white;
	}

	h3 {
		color: $color-white;
	}

	.icon-external-link-new-window,
	.icon-download,
	.icon-internal-link,
	.ul--rte li::before,
	.ol--rte li::before {
		color: $color-white;
	}

	.icon-external-link-new-window::before,
	.icon-internal-link::before,
	.icon-download::before {
		color: $color-yellow;
	}

	.skin-openclose {
		padding: 0 15px 0 15px;

		.content {
			display: none;
			padding: 20px 0px 5px;
		}

		.title {
			font-size: 1.10em;
			padding: 20px 6% 15px 0;
			margin-left: 32px;

			&:hover {
				.button-openclose:before,
				.button-openclose:after {
					color: $color-white;
				}
			}
		}
	}

	.block-openclose {
		.ul--rte,
		.ol--rte {
			list-style: none;
			margin: 0 0 0px;
		}

		li {
			padding: 0;
		}

		.title {
			border-bottom: 1px solid $color-yellow;
			color: $color-white;
		}
	}

	.button-openclose {
		color: $color-yellow;
	}

	.link--type {
		padding-bottom: 15px;
		color: $color-white;

		.icon-plus {
			color: $color-blue;

			&::before {
				box-shadow: inset 0 0 0 22px $color-yellow;
			}

			html.no-touch & {
				color: $color-blue;
				background-color: $color-white;
			}
		}
	}

	.btn--bb {
		margin-right: 10px;
		border-color: $color-white;
		color: $color-white;
		background-color: $color-blue;
	}

	.btn--jb {
		border-color: $color-yellow;
		color: $color-blue;
		background-color: $color-yellow;

		html.no-touch &:hover {
			color: $color-white;
			border-color: $color-white;

			&::before {
				background-color: $color-blue;
			}
		}
	}
}