.zoom-slider {
    position: relative;

    @media #{$screen-m-rule-min} {
        padding-right: 60px;
        padding-left: 60px;
    }

    &--item {
        display: grid;
        gap: 20px;

        @media #{$screen-l-rule-min} {
            grid-template-columns: 2fr 1fr;
            align-items: center;
            column-gap: 30px;
        }
    }

    &--image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &--content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }

    &--title {
        @include font-size(17px);
        margin: 0;
        border-left: 4px solid $color-yellow;
        font-weight: 700;
        text-align: left;
        text-transform: uppercase;

        @media #{$screen-m-rule-min} {
            @include font-size(20px);
        }
    }

    &--title-link {
        text-decoration: none;

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }

    &--summary {
        p {
            @include font-size(14px);
            margin-bottom: 0;
            line-height: 1.5;
            text-align: left;

            @media #{$screen-m-rule-min} {
                @include font-size(17px);
            }
        }
    }

    &--read-more {
        margin-bottom: 0;
    }

    .swiper-wrapper {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .swiper-pagination {
        display: flex;
        gap: 10px;
        width: 100%;
        height: 20px;
        position: static;
        padding-top: 20px;
    }

    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        background-color: $color-gray-light;
        box-shadow: none;

        &:after {
            width: 10px;
            height: 10px;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        @include font-size(60px);
        @include transition(all ease 0.5s);
        display: none;
        width: 40px;
        height: 40px;
        border: 0;
        color: $color-gray-light;
        background-color: transparent;

        @media #{$screen-m-rule-min} {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:focus,
        &:hover {
            color: $color-blue;
        }
    }

    .swiper-button-prev {
        left: 0;
    }

    .swiper-button-next {
        right: 0;
    }
}


