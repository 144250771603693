#annuaire {
    .box {
        display: inline-block;
    }
    .directory--block {
        display: inline-block; 
        vertical-align: top; 
        margin-left: 20px;
    }
    .ul--rte li {
        clear: both;
    }
    .framed {
        div {
            display:inline-block;
        }
        img {
            display:none;
            
            @media #{$screen-l-rule-min} {
                display:inline-block;
                float: right;
            }
        }
        .icon-tri {
            display: none;
            
            @media #{$screen-s-rule-min} {
                display: inline-block;
                vertical-align: middle;
                padding: 0 20px;
            
                @include font-size(60px);
            }
        }
    }
}

.ul--directory {
    list-style: none;
    
    @media #{$screen-l-rule-min} {
        border-bottom: 1px solid $color-gray-light;
        padding: 0 0 30px 0;
    }
    
    .li--directory {
        width:100%; 
        padding-right: 15px;
        
        @media #{$screen-l-rule-min} {
            width: 49%;
            padding-left:40px;
            display: inline-block;
            vertical-align: top;
        
            &:first-of-type {
                border-right: 1px solid $color-gray-light;
                padding-left: 0;
            }
        }
    }
    
    &:last-of-type {
        border-bottom: none;
    }
}

@media #{$screen-m-rule-min} {
	.directory--block .tonnage {
        line-height: 40px;
    }
    
    .directory--block .icon-biodechet {
        font-size: 3rem;
	}
}