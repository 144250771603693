.box--city {
    .clearfix {        
        img {
            padding: 20px 0 0 20px;
            float: left;
        }
        .float--right {  
            display: table;
            padding: 20px;
            float: none;
        }
    }
}