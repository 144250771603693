.box-mag {
    width: 100%;
    padding: 40px 30px;
    border-radius: 8px;
    background-color: $color-gray-verylight;

    &--title {
        @include font-size(16px);
        margin-top: 0;
        font-weight: 700;
    }

    &--grid {
        display: grid;
        gap: 30px;

        @media #{$screen-l-rule-min} {
            grid-template-rows: 1fr;
            grid-template-columns: auto 1fr;
        }
    }

    &--subtitle {
        @include font-size(16px);
        margin-top: 0;
    }
}