.arc_sondage {

    p {
        margin: 0px;
    }

    .btn {
        @media #{$screen-m-rule-min} {
            margin-right: 280px;
        }
    }

    .field--label{
        width: 99%;
        text-align:left;
        padding-bottom: 14px;
    }

    .field--item {
        .field--radio {
            padding-left: 20px;
        }

        .field--checkbox {
            padding-left: 20px;
        }
    }
}

.arc_sondage.field--label label{
    width: 100%;
}