.chapo {
    p {
        color: $color-blue;
        line-height: 1.6;
        font-family: $serif-font-family, serif;
        
        @include font-size(17px);
    }
}


