.card-job-grid {
    display: grid;
    gap: 20px;
    margin: 0 0 30px 0;
    padding: 0;

    @media #{$screen-m-rule-min} {
        grid-template-columns: 1fr 1fr;
    }

    @media #{$screen-l-rule-min} {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.card-job {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid $color-blue-dark;
    background-color: $color-blue-dark;

    &--title {
        margin: 0;
        padding: 20px 15px;
        color: $color-blue;
        background-color: $color-white;
    }

    &--content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        height: 100%;
        padding: 20px;
    }

    &--list {
        margin: 0;
        padding: 0;
    }

    &--item {
        color: $color-white;
    }

    &--read-more {
        margin: 0;
        text-align: right;

        a {
            color: $color-white;
        }
    }
}